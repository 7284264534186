import { useContext, useEffect, useState } from "react";
import item from "./img/item.svg";
import "./style.css";
import { UserContext } from "../../Context/user";
import { getShop, getUserSell } from "../../Api/Api";
import { Zoom } from "react-reveal";
import formDate from "../../Func/Time";
export default function Buy() {
  const [sells, setSells] = useState();
  const [user, setUser] = useContext(UserContext);
  const [shops, setShops] = useState();

  useEffect(() => {
    if (user && user.ID) {
      getUserSell(user.ID).then((data) => {
        setSells(data);
      });
    }
    getShop().then((data) => {
      setShops(data);
    });
  }, [user]);
  return (
    <div className="buy">
      <div className="nav" onClick={() => window.history.back()}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 7L9 13L15 19"
            stroke="#474753"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p>Покупки</p>
      </div>
      <div className="buyItems">
        {sells &&
          sells.length > 0 &&
          sells.map((em, index) => {
            if (em.operation.TypeOperation != "cancellation") {
              return (
                <Zoom duration={700}>
                  <div className="buyItem" key={`buyItem${index}`}>
                    <div className="balls">
                      <p>Баллы</p>
                      <span>{em.operation.CashSumm}</span>
                    </div>
                    <div className="rightBuyItem">
                      <span>{formDate(em.operation.TimeOperation)}</span>
                      <p>{em.shop.Name} </p>
                      <div>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="20" height="20" rx="10" fill="#F61A41" />
                          <path
                            d="M7.02734 9.99982L9.18951 12.162L13.7841 7.56738"
                            stroke="white"
                            stroke-width="1.62162"
                          />
                        </svg>
                        <p>Баллы зачислены</p>
                        <span>{em.operation.Summ} ₽</span>
                      </div>
                    </div>
                  </div>
                </Zoom>
              );
            } else if (em.operation.TypeOperation == "cancellation") {
              return (
                <Zoom duration={700}>
                  <div className="buyItem cancelled" key={`buyItem${index}`}>
                    <div className="balls">
                      <p>Баллы</p>
                      <span>{em.operation.CashSumm}</span>
                    </div>
                    <div className="rightBuyItem">
                      <span>{formDate(em.operation.TimeOperation)}</span>
                      <p>{em.shop.Name} </p>
                      <div>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="20" height="20" rx="10" fill="#DDDFE6" />
                          <path
                            d="M11.3266 8.89952C11.9537 8.32779 12.8018 7.55455 12.8791 5.70728H13.439V5.15308H6.23438V5.70728H6.79428C6.87157 7.55455 7.71961 8.32779 8.34671 8.89952C8.76736 9.28304 9.00537 9.51778 9.00537 9.88224C9.00537 10.2467 8.76736 10.4814 8.34671 10.865C7.71961 11.4367 6.87157 12.2099 6.79428 14.0572H6.23438V14.6114H13.439V14.0572H12.8791C12.8018 12.2099 11.9537 11.4367 11.3266 10.865C10.906 10.4814 10.668 10.2467 10.668 9.88224C10.668 9.51778 10.906 9.28304 11.3266 8.89952ZM9.55957 11.9593C9.47398 11.977 9.38943 12.0027 9.30691 12.0368L7.60964 12.7392C7.88681 12.0344 8.33324 11.6272 8.72009 11.2745C9.15181 10.8809 9.55957 10.5091 9.55957 9.88224V11.9593ZM10.9533 11.2745C11.3401 11.6272 11.7865 12.0344 12.0637 12.7392L10.3664 12.0368C10.2839 12.0027 10.1994 11.977 10.1138 11.9593V9.88224C10.1138 10.5091 10.5215 10.8809 10.9533 11.2745ZM7.5999 7.00041C7.46592 6.65128 7.37338 6.22974 7.34917 5.70728H12.3242C12.2999 6.22974 12.2074 6.65128 12.0734 7.00041H7.5999Z"
                            fill="#8A8E9E"
                          />
                        </svg>

                        <p>
                          Аннули- <br /> ровано
                        </p>
                        <span>{em.operation.Summ} ₽</span>
                      </div>
                    </div>
                  </div>
                </Zoom>
              );
            }
          })}
      </div>
    </div>
  );
}

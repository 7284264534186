import "./style.css";
export default function SelectComponent({ select, setSelect, currentSelect }) {
  return (
    <div className="SelectNew">
      <div className="activeSel">{currentSelect}</div>
      <div className="hiddenSel">
        {select &&
          select.length > 0 &&
          select.map((em) => {
            if (currentSelect != em.Name) {
              return (
                <div
                  onClick={() => {
                    setSelect(em.Name);
                  }}
                  className="SelectItem"
                >
                  {em.Name}
                </div>
              );
            }
          })}
      </div>
    </div>
  );
}

import { useContext, useEffect, useState } from "react";

import "./style.css";
import { routes } from "../../../routes";
import { useNavigate } from "react-router-dom";
import { getOperations, getUsers } from "../../../Api/Api";
import AdminHeader from "../../../Components/AdminHeader";
import AdminMenu from "../../../Components/AdminMenu";
import { AdminContext } from "../../../Context/admin";
import formDate from "../../../Func/Time";
import usersTableCSV from "../../../Func/UserTable";
import Notice from "../../../Func/Func";
import compareDate from "../../../Func/CompareDate";
export default function AdminUsers() {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [beginDate, setBeginDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [operations, setOperations] = useState([]);
  useEffect(() => {
    getOperations().then((data) => {
      setOperations(data);
    });
  }, []);
  const [admin, setAdmin] = useContext(AdminContext);
  const Navigate = useNavigate();
  useEffect(() => {
    getUsers().then((data) => {
      setUsers(data);
    });
  }, []);
  function completeArr() {
    let finalArr;
    finalArr = users.map((user) => {
      let operation = operations.filter((operation) => {
        let date = new Date(compareDate(operation.DateOperation));
        if (
          operation.IdUser == user.ID &&
          date >= new Date(beginDate) &&
          date <= new Date(endDate) &&
          operation.TypeOperation != "cancellation"
        ) {
          return operation;
        }
      });
      console.log(operation);
      return {
        fio: user.FIO,
        phone: user.Phone.slice(0, 10) + " ****",
        lcUk: user.Uk,
        lcEl: user.FaceNumberElectr,
        lcGas: user.FaceNumberGas,
        lcWater: user.FaceNumberWater,
        summ:
          operation && operation.length > 0
            ? operation.reduce((a, b) => a + Number(b.Summ), 0).toFixed(0)
            : 0,
        summCash:
          operation && operation.length > 0
            ? operation.reduce((a, b) => a + Number(b.CashSumm), 0).toFixed(0)
            : 0,
        summAv:
          operation && operation.length > 0
            ? operation.reduce((a, b) => a + Number(b.AgencySumm), 0).toFixed(0)
            : 0,
        ukName: user.UkName,
      };
    });
    let nameCsv =
      formDate(beginDate) + "-" + formDate(endDate) + " Пользователи.csv";

    usersTableCSV(finalArr, nameCsv);
    Notice(
      "Отчеты",
      "Отчет по пользователям формируется, дождись окончания загрузки",
      "success"
    );
  }
  return (
    <div className="cont">
      <AdminMenu />
      <div className="mainCont">
        <AdminHeader></AdminHeader>
        <div className="usersCont">
          <div className="buttonsAndOther">
            <h4>Пользователи</h4>
            <input
              type="text"
              value={search}
              placeholder="Поиск.."
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="currentDate">
              <p>От</p>
              <input
                type="date"
                name=""
                id=""
                onChange={(e) => setBeginDate(e.target.value)}
                value={beginDate}
              />
              <p>До</p>
              <input
                type="date"
                name=""
                id=""
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
              />
            </div>
            <div
              className="downloadOth"
              onClick={() => {
                if (beginDate.length > 0 && endDate.length > 0) {
                  completeArr();
                } else {
                  Notice(
                    "Ошибка",
                    "Не выбраны даты для формирования отчета",
                    "danger"
                  );
                }
              }}
            >
              Скачать отчет
              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.46967 12.0303C5.76256 12.3232 6.23744 12.3232 6.53033 12.0303L11.3033 7.25736C11.5962 6.96447 11.5962 6.48959 11.3033 6.1967C11.0104 5.90381 10.5355 5.90381 10.2426 6.1967L6 10.4393L1.75736 6.1967C1.46447 5.90381 0.989592 5.90381 0.696699 6.1967C0.403805 6.48959 0.403805 6.96447 0.696699 7.25736L5.46967 12.0303ZM5.25 0.5L5.25 11.5L6.75 11.5L6.75 0.5L5.25 0.5Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <div className="partnersTable">
            <div className="adminTableTitle rzd">
              <div className="ava littleColumn">ID</div>
              <div className="pName">ФИО</div>
              <div className="pRub littleColumnl">Телефон</div>
              <div className="pUser">Email</div>
              <div className="pTel littleColumnd">Дата рег.</div>
              <div className="pWeb">Город, Адресс</div>
              <div className="pAdress littleColumnl">Лицевой счет УК</div>
              <div className="pAdress littleColumnd">Пол</div>
              <div className="pGr">Пароль</div>
            </div>
            {users &&
              users.length > 0 &&
              users.map((em, index) => {
                {
                  if (
                    em.FIO.toLowerCase().includes(search.toLocaleLowerCase()) &&
                    JSON.parse(em.Adress)
                      .value.toLowerCase()
                      .includes(admin.City.toLowerCase())
                  ) {
                    return (
                      <div
                        className="admPcol rzd"
                        key={"partner" + index}
                        onClick={() =>
                          Navigate(routes.adminUserSellData + "?key=" + em.ID)
                        }
                      >
                        <div className="ava littleColumn">{em.ID}</div>
                        <div className="pName">{em.FIO}</div>
                        <div className="pRub littleColumnl">{em.Phone}</div>
                        <div className="pUser">{em.Email}</div>
                        <div className="pTel littleColumnd">
                          {formDate(em.DateRegistration)}
                        </div>
                        <div className="pWeb">
                          {JSON.parse(em.Adress).value}
                        </div>
                        <div className="pAdress littleColumnl">{em.Uk}</div>
                        <div className="pAdress littleColumnd">{em.Sex}</div>
                        <div className="pGr">{em.Password}</div>
                      </div>
                    );
                  }
                }
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

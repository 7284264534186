import { useContext, useEffect, useState } from "react";
import AdminHeader from "../../../Components/AdminHeader";
import AdminMenu from "../../../Components/AdminMenu";
import "./style.css";
import { createCsv, getOperations, getShop, getUsers } from "../../../Api/Api";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routes";
import Select from "react-select";
import { AdminContext } from "../../../Context/admin";
import CsvDownload from "../../../Components/CreateCsv/CreateCsv";
import formDate from "../../../Func/Time";
import downloadCSV from "../../../Func/CreateCsv";
import Notice from "../../../Func/Func";
import SelectComponent from "../../../Components/SelectComponent";
import formDateFNS from "../../../Func/FormDateFNS";

export default function AdminPartners() {
  const [currentPartners, setCurrentPartners] = useState([]);
  const arr = [
    {
      val: "Name",
      Name: "Название",
    },
    {
      val: "City",
      Name: "Адрес",
    },
    {
      val: "category",
      Name: "Рубрика",
    },
  ];

  const [beginDate, setBeginDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentSearch, setCurrentSearch] = useState(arr[0]);
  const [search, setSearch] = useState("");
  const [partners, setPartners] = useState([]);
  const Navigate = useNavigate();
  const [admin, setAdmin] = useContext(AdminContext);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (
      currentPartners.length == partners.length &&
      currentPartners.length != 0 &&
      partners.length != 0
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [currentPartners]);
  function CreateObjCsv() {
    getOperations().then((data) => {
      console.log(data);
      currentPartners.map((part) => {
        let operations = []; //массив операций
        let finalArr = [];
        let Inn;
        operations = data.filter((em) => {
          let date = new Date(em.TimeOperation);
          let beg = new Date(beginDate);
          let end = new Date(endDate);
          return (
            em.IdShop == part &&
            date >= beg &&
            date <= end &&
            em.TypeOperation == "success"
          );
        });

        getUsers().then((user) => {
          operations.map((em) => {
            let userObj = user.find((man) => man.ID == em.IdUser);
            Inn = partners.find((partner) => partner.ID == em.IdShop).INN;
            let finalObj = {
              fio: userObj.FIO,
              phone: userObj.Phone,
              uk: userObj.UkName,
              adress: partners.find((partner) => partner.ID == em.IdShop).City,
              date: formDateFNS(em.DateOperation),
              fn: userObj.FaceNumber,
              summ: em.Summ,
              cashBack: em.CashSumm,
              av: em.AgencySumm,
              limit:
                Number(em.Summ) >
                Number(
                  partners.find((partner) => partner.ID == em.IdShop).MaxSumm
                )
                  ? em.Summ
                  : "",
            };
            finalArr.push(finalObj);
          });
          let summBuy = 0;
          let cashUser = 0;
          let cashAv = 0;
          let summF = 0;
          for (let i = 0; i < finalArr.length; i++) {
            summBuy += Number(finalArr[i].summ);
            cashUser += Number(finalArr[i].cashBack);
            cashAv += Number(finalArr[i].av);
            summF += Number(finalArr[i].cashBack) + Number(finalArr[i].av);
          }

          let paramObj = [
            "",
            Inn,
            formDate(beginDate) + "-" + formDate(endDate),
            summBuy,
            operations.length > 0 ? operations[0].CashPercent : "",
            cashUser,
            operations.length > 0 ? operations[0].AgencyPercent : "",
            cashAv,
            summF,
          ];
          console.log(beginDate);
          console.log(formDate(beginDate));
          downloadCSV(
            finalArr,
            partners.find((name) => name.ID == part).Name +
              " " +
              formDate(beginDate) +
              "-" +
              formDate(endDate) +
              ".csv",
            paramObj
          );
        });
      });
    });
    Notice(
      "Отчеты",
      "Отчеты формируются, дождись окончания загрузки",
      "success"
    );
  }
  useEffect(() => {
    getShop().then((data) => {
      setPartners(data);
    });
  }, []);

  return (
    <div className="AdminPartners cont">
      <AdminMenu></AdminMenu>

      <div className="mainCont">
        <AdminHeader></AdminHeader>
        <div className="adminPartnersBody">
          <div className="buttonsAndOther">
            <div className="SelectNew">
              <div className="activeSel">{currentSearch.Name}</div>
              <div className="hiddenSel">
                {arr &&
                  arr.length > 0 &&
                  arr.map((em) => {
                    if (currentSearch.Name != em.Name) {
                      return (
                        <div
                          onClick={() => {
                            setCurrentSearch(em);
                          }}
                          className="SelectItem"
                        >
                          {em.Name}
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
            <input
              type="text"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              placeholder="Поиск.."
            />
            <div
              className="addPartner"
              onClick={() => Navigate(routes.adminAddPartner)}
            >
              Добавить партнера
            </div>
            <div className="currentDate">
              <p>От</p>
              <input
                type="date"
                name=""
                id=""
                onChange={(e) => setBeginDate(e.target.value)}
                value={beginDate}
              />
              <p>До</p>
              <input
                type="date"
                name=""
                id=""
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
              />
            </div>

            <div
              className="downloadOth"
              onClick={() => {
                if (
                  beginDate.length > 0 &&
                  endDate.length > 0 &&
                  currentPartners.length > 0
                ) {
                  CreateObjCsv();
                } else {
                  Notice(
                    "Ошибка",
                    "Не выбраны даты для формирования отчета или партнеры",
                    "danger"
                  );
                }
              }}
            >
              Скачать отчет
              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.46967 12.0303C5.76256 12.3232 6.23744 12.3232 6.53033 12.0303L11.3033 7.25736C11.5962 6.96447 11.5962 6.48959 11.3033 6.1967C11.0104 5.90381 10.5355 5.90381 10.2426 6.1967L6 10.4393L1.75736 6.1967C1.46447 5.90381 0.989592 5.90381 0.696699 6.1967C0.403805 6.48959 0.403805 6.96447 0.696699 7.25736L5.46967 12.0303ZM5.25 0.5L5.25 11.5L6.75 11.5L6.75 0.5L5.25 0.5Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <div className="partnersTable">
            <div className="adminTableTitle rzd">
              <div className="checkW">
                <button
                  className="curBtn"
                  onClick={() => {
                    if (!selectAll) {
                      setCurrentPartners(partners.map((em) => em.ID));
                    } else {
                      setCurrentPartners([]);
                    }
                  }}
                >
                  {selectAll ? (
                    <svg
                      className="zooom"
                      width="25"
                      height="26"
                      viewBox="0 0 25 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        y="0.5"
                        width="25"
                        height="25"
                        rx="5"
                        fill="#F61A41"
                      />
                      <path
                        d="M6.25 12.5L10.75 17L19 9"
                        stroke="white"
                        stroke-width="3"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="zooom"
                      width="25"
                      height="26"
                      viewBox="0 0 25 26"
                      fill="#fff"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="1"
                        width="24"
                        height="24"
                        rx="4.5"
                        stroke="#F61A41"
                      />
                    </svg>
                  )}
                </button>
              </div>
              <div className="ava">Логотип</div>
              <div className="pName">Название</div>
              <div className="pRub">Рубрика</div>
              <div className="pUser checkP">% польз.</div>
              <div className="pTel">Телефон</div>
              <div className="pWeb">Вебсайт</div>
              <div className="pAdress">Адрес</div>
              <div className="pGr">График работы</div>
            </div>
            {partners && partners.length > 0 && admin.isAdmin == "1"
              ? partners.map((em, index) => {
                  if (
                    em[currentSearch.val]
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  ) {
                    return (
                      <div
                        className="admPcol rzd"
                        key={"partner" + index}
                        onClick={() =>
                          Navigate(
                            routes.adminPartnerData +
                              "?key=" +
                              JSON.stringify(em)
                          )
                        }
                      >
                        <div className="checkW">
                          <button
                            className="curBtn"
                            style={
                              currentPartners.find((elem) => elem == em.ID)
                                ? {
                                    color: "#fff",
                                    background: "#f61a41",
                                  }
                                : {}
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                currentPartners.find((elem) => elem == em.ID)
                              ) {
                                setSelectAll(false);
                                setCurrentPartners((currentPartners) =>
                                  currentPartners.filter(
                                    (item) => item !== em.ID
                                  )
                                );
                              } else {
                                setCurrentPartners([...currentPartners, em.ID]);
                              }
                            }}
                          >
                            {currentPartners.find((elem) => elem == em.ID) ? (
                              <svg
                                className="zooom"
                                width="25"
                                height="26"
                                viewBox="0 0 25 26"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  y="0.5"
                                  width="25"
                                  height="25"
                                  rx="5"
                                  fill="#F61A41"
                                />
                                <path
                                  d="M6.25 12.5L10.75 17L19 9"
                                  stroke="white"
                                  stroke-width="3"
                                />
                              </svg>
                            ) : (
                              <svg
                                className="zooom"
                                width="25"
                                height="26"
                                viewBox="0 0 25 26"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="0.5"
                                  y="1"
                                  width="24"
                                  height="24"
                                  rx="4.5"
                                  stroke="#F61A41"
                                />
                              </svg>
                            )}
                          </button>
                        </div>
                        <div className="ava">
                          <img
                            src={em.Image.length > 0 ? em.Image : ""}
                            alt=""
                          />
                        </div>
                        <div className="pName">{em.Name}</div>
                        <div className="pRub">{em.category}</div>
                        <div className="pUser checkP">{em.SaleForClients}%</div>
                        <div className="pTel">{em.Tel}</div>
                        <div className="pWeb">{em.WebSyte}</div>
                        <div className="pAdress">{em.City}</div>
                        <div className="pGr">{em.WorkSchedule}</div>
                      </div>
                    );
                  }
                })
              : partners && partners.length > 0 && admin.isAdmin == "0"
              ? partners.map((em, index) => {
                  if (
                    em[currentSearch.value]
                      .toLowerCase()
                      .includes(search.toLowerCase()) &&
                    admin.City == em.City
                  ) {
                    return (
                      <div
                        className="admPcol rzd"
                        key={"partner" + index}
                        onClick={() =>
                          Navigate(
                            routes.adminPartnerData +
                              "?key=" +
                              JSON.stringify(em)
                          )
                        }
                      >
                        <div className="ava">
                          <img
                            src={em.Image.length > 0 ? em.Image : ""}
                            alt=""
                          />
                        </div>
                        <div className="pName">{em.Name}</div>
                        <div className="pRub">{em.category}</div>
                        <div className="pUser">{em.SaleForClients}</div>
                        <div className="pTel">{em.Tel}</div>
                        <div className="pWeb">{em.WebSyte}</div>
                        <div className="pAdress">{em.City}</div>
                        <div className="pGr">{em.WorkSchedule}</div>
                      </div>
                    );
                  }
                })
              : ""}
          </div>
        </div>
      </div>
    </div>
  );
}

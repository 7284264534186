import "./App.css";
import Header from "./Components/Header";
import Menu from "./Components/Menu";
import Main from "./Pages/Main";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import { routes } from "./routes";
import News from "./Pages/News";
import Buy from "./Pages/Buy";
import FirstScreen from "./Pages/Instructions/FirstScreen";
import Slider from "./Pages/Instructions/Slider";
import Registration from "./Pages/Registration";
import RegistrationComplete from "./Pages/RegistrationComplete";
import PageOfNews from "./Pages/PageOfNews";
import { useEffect, useState } from "react";
import Sales from "./Pages/Sales";
import SalePage from "./Pages/SalePage";
import Partners from "./Pages/Partners";
import PartnerPage from "./Pages/PartnerPage";
import { ReactNotifications } from "react-notifications-component";
import "animate.css";
import "react-notifications-component/dist/theme.css";
import Questions from "./Pages/Questions";
import Profile from "./Pages/Profile";
import AuthAdmin from "./Pages/Admin/AuthAdmin";
import AdminMenu from "./Components/AdminMenu";
import AdminPartners from "./Pages/Admin/AdminPartners";
import AdminActions from "./Pages/Admin/AdminActions";
import AdminPartnerData from "./Pages/Admin/AdminPartnerData";
import AdminNews from "./Pages/Admin/AdminNews";
import AdminUsers from "./Pages/Admin/AdminUsers";
import AdminMainPage from "./Pages/Admin/AdminMainPage";
import AdminDillers from "./Pages/Admin/AdminDillers";
import AdminQuestions from "./Pages/Admin/AdminQuestions";
import AdminAddPartner from "./Pages/Admin/AdminAddPartner";
import { AdminUserPage } from "./Pages/Admin/AdminUserPage";
import "react-dadata/dist/react-dadata.css";
import Auth from "./Pages/Auth";
import { UserContext } from "./Context/user";
import AdminUserSellData from "./Pages/Admin/AdminUserSellData";
import { AdminContext } from "./Context/admin";
import { auth, authAdmin } from "./Api/Api";
import DillerPage from "./Pages/Admin/AdminDillers/DillerPage";
import { CityContext } from "./Context/city";
import eruda from "eruda";
import AdminCategories from "./Pages/Admin/AdminCategories";
function App() {
  const location = useLocation();
  const [user, setUser] = useState(
    localStorage.userData ? JSON.parse(localStorage.userData) : null
  );
  const [currentCity, setCurrentCity] = useState(
    localStorage.city ? localStorage.city : ""
  );
  useEffect(() => {
    eruda.init();
  }, []);
  useEffect(() => {
    localStorage.userData = JSON.stringify(user);
    if (user && user.Adress) {
      setCurrentCity({
        value: JSON.parse(user.Adress).data.city,
        label: JSON.parse(user.Adress).data.city,
      });
    }
  }, [user]);
  useEffect(() => {
    localStorage.city = currentCity;
  }, [currentCity]);
  const [adminData, setAdminData] = useState(
    localStorage.adminData ? JSON.parse(localStorage.adminData) : null
  );

  useEffect(() => {
    if (
      adminData &&
      adminData.Login &&
      adminData.Password &&
      location.pathname.includes("Admin")
    ) {
      authAdmin(adminData.Login, adminData.Password).then((data) => {
        if (data[0].Activate == 0 || data == "error") {
          localStorage.clear();
          window.open("/authAdmin");
        } else {
          setAdminData(data[0]);
        }
      });
    } else if (
      user &&
      user.Phone &&
      user.Password &&
      location.pathname != routes.registration &&
      location.pathname != routes.auth &&
      location.pathname != routes.completeRegister &&
      location.pathname != routes.instructions &&
      location.pathname != routes.firstScreen &&
      location.pathname != "/" &&
      !location.pathname.includes("Admin")
    ) {
      auth(user.Phone, user.Password).then((response) => {
        if (response != "err") {
          setUser(response);
          localStorage.checkIsOut = "1";
        } else {
          localStorage.clear();
          window.location.href = routes.auth;
        }
      });
    } else if (
      !user &&
      location.pathname != routes.registration &&
      location.pathname != routes.auth &&
      location.pathname != routes.completeRegister &&
      location.pathname != routes.instructions &&
      location.pathname != routes.firstScreen &&
      location.pathname != "/" &&
      !location.pathname.includes("Admin")
    ) {
      localStorage.clear();
      window.location.href = routes.auth;
    }
  }, [location.pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <div className="App">
      <ReactNotifications />
      <CityContext.Provider value={[currentCity, setCurrentCity]}>
        <UserContext.Provider value={[user, setUser]}>
          {location.pathname != "/" &&
            location.pathname != routes.instructions &&
            location.pathname != routes.registration &&
            location.pathname != routes.completeRegister &&
            location.pathname != routes.auth &&
            !location.pathname.includes("Admin") && <Header></Header>}
          <Routes>
            <Route path={routes.main} element={<Main />} />
            <Route path={routes.news} element={<News />} />
            <Route path={routes.buy} element={<Buy />} />
            <Route path={"/"} element={<FirstScreen />} />
            <Route path={routes.instructions} element={<Slider />} />
            <Route path={routes.registration} element={<Registration />} />
            <Route path={routes.auth} element={<Auth />} />
            <Route
              path={routes.completeRegister}
              element={<RegistrationComplete />}
            />
            <Route path={routes.pageofnews} element={<PageOfNews />} />
            <Route path={routes.sales} element={<Sales />} />
            <Route path={routes.salePage} element={<SalePage />} />
            <Route path={routes.partners} element={<Partners />} />
            <Route path={routes.partnerPage} element={<PartnerPage />} />
            <Route path={routes.questions} element={<Questions />} />
            <Route path={routes.profile} element={<Profile />} />
          </Routes>{" "}
          <AdminContext.Provider value={[adminData, setAdminData]}>
            <Routes>
              <Route path={routes.authAdmin} element={<AuthAdmin />} />
              <Route path={routes.adminPartners} element={<AdminPartners />} />
              <Route path={routes.adminActions} element={<AdminActions />} />
              <Route path={routes.rubrik} element={<AdminCategories />} />
              <Route
                path={routes.adminPartnerData}
                element={<AdminPartnerData />}
              />
              <Route path={routes.adminNews} element={<AdminNews />} />
              <Route path={routes.adminUsers} element={<AdminUsers />} />
              <Route
                path={routes.adminUserSellData}
                element={<AdminUserSellData />}
              />
              <Route path={routes.adminMain} element={<AdminMainPage />} />
              <Route path={routes.adminDillets} element={<AdminDillers />} />
              <Route
                path={routes.adminQuestions}
                element={<AdminQuestions />}
              />
              <Route
                path={routes.adminAddPartner}
                element={<AdminAddPartner />}
              />
              <Route path={routes.dillersPage} element={<DillerPage />} />
              <Route path={routes.adminUserPage} element={<AdminUserPage />} />
            </Routes>{" "}
          </AdminContext.Provider>
        </UserContext.Provider>
      </CityContext.Provider>
      {location.pathname != "/" &&
        location.pathname != routes.instructions &&
        location.pathname != routes.registration &&
        location.pathname != routes.completeRegister &&
        location.pathname != routes.auth &&
        !location.pathname.includes("Admin") && <Menu></Menu>}
    </div>
  );
}

export default App;

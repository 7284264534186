import AdminHeader from "../../../Components/AdminHeader";
import AdminMenu from "../../../Components/AdminMenu";
import "./style.css";
import add from "./add.svg";
import logo from "./logo.png";
import addimg from "./addimg.png";
import { useContext, useEffect, useRef, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import {
  addSale,
  changeSale,
  deleteSale,
  getSales,
  getShop,
  getShopName,
  uploadImage,
} from "../../../Api/Api";
import { Store } from "react-notifications-component";
import Select from "react-select";
import { AdminContext } from "../../../Context/admin";
export default function AdminActions() {
  const [sales, setSales] = useState();
  const [img, setImg] = useState([]);
  const [file, setFile] = useState(null);
  const [changeFile, setChangeFile] = useState(null);
  const [change, isChange] = useState(false);
  //Поля
  const [name, setName] = useState();
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [time, setTime] = useState("");
  const [timeBefore, setTimeBefore] = useState("");
  const [currentItem, setCurrentItem] = useState(null);
  const fileTypes = ["JPG", "PNG", "WEBP", "GIF"];
  const [currentShop, setCurrentShop] = useState("");
  const [shops, setShops] = useState();
  const [pop, setPop] = useState(false);
  const [admin, setAdmin] = useContext(AdminContext);
  const [checkName, setCheckName] = useState([]);
  useEffect(() => {
    getShopName().then((data) => {
      setShops(
        data.map((em) => {
          return {
            value: em.Name,
            label: em.Name,
          };
        })
      );
    });
  }, []);
  useEffect(() => {
    let arrS = [];
    getShop().then((data) => {
      data.map((em) => {
        if (em.City == admin.City) {
          arrS.push(em.Name);
        }
      });
      setCheckName(arrS);
    });
  }, []);
  function save() {
    const data = new FormData();

    data.append("file", file);
    fetch("https://domcash.ru/avatar.php", {
      method: "post",
      body: data,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const pathImg = data;
        let surname = new FormData();
        surname.append("name", name.value);
        fetch("https://domcash.ru/api/shop/pathShop.php", {
          method: "POST",
          body: surname,
        })
          .then((response) => {
            return response.json();
          })
          .then((logo) => {
            addSale(
              name.value,
              time,
              title,
              desc,
              pathImg,
              logo[0].Image,
              timeBefore
            ).then((status) => {
              if (status == 200) {
                Store.addNotification({
                  title: "Акции",
                  message: `Акция успешно добавлена`,
                  type: "success",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animate__animated", "animate__bounceIn"],
                  animationOut: ["animate__animated", "animate__backOutUp"],
                  dismiss: {
                    duration: 3000,
                    onScreen: true,
                  },
                });
                setFile(null);
                setDesc("");
                setName("");
                setTime();
                setTitle("");
                getSales().then((data) => {
                  setSales(data);
                });
              }
            });
          });
      });
  }

  useEffect(() => {
    currentItem ? isChange(true) : isChange(false);
  }, [currentItem]);
  useEffect(() => {
    getSales().then((data) => {
      setSales(data);
    });
  }, []);
  const handleChange = (file) => {
    setFile(file);
  };
  const handleChangeFile = (file) => {
    uploadImage(file).then((data) => {
      setCurrentItem({ ...currentItem, Image: "https://domcash.ru/" + data });
    });
  };
  const handleChangeName = (selectedOption) => {
    setName(selectedOption);
  };
  const handleChangeCurrent = (name, e) => {
    setCurrentItem({ ...currentItem, [name]: e.target.value });
  };
  const handleChangeName2 = (sel) => {
    setCurrentItem({ ...currentItem, Name: sel.value });
  };
  return (
    <div className="cont AdminActions">
      <AdminMenu></AdminMenu>
      <div className="mainCont">
        {pop && (
          <div className="deleteMe">
            <div className="deletMeBody">
              <h3>Вы действительно хотите удалить акцию?</h3>
              <div>
                <div
                  className="saveData"
                  onClick={() => {
                    setPop(false);
                  }}
                >
                  Отменить
                </div>
                <div
                  className="backData"
                  onClick={() =>
                    deleteSale(currentItem.ID).then((status) => {
                      if (status == 200) {
                        Store.addNotification({
                          title: "Акции",
                          message: `Акция успешно удалена`,
                          type: "success",
                          insert: "top",
                          container: "top-right",
                          animationIn: [
                            "animate__animated",
                            "animate__bounceIn",
                          ],
                          animationOut: [
                            "animate__animated",
                            "animate__backOutUp",
                          ],
                          dismiss: {
                            duration: 3000,
                            onScreen: true,
                          },
                        });
                        setPop(false);
                      }
                      getSales().then((data) => {
                        setSales(data);
                      });
                    })
                  }
                >
                  Удалить
                </div>
              </div>
            </div>
          </div>
        )}
        <AdminHeader></AdminHeader>
        <div className="actionsBodyAdmin">
          <div className="actionsLeft">
            <h4>Акции</h4>
            <p>Выберите акцию для редактирования</p>

            {sales && sales.length > 0 && admin.isAdmin == "1"
              ? sales.map((em, index) => {
                  return (
                    <div
                      className="actionItemAdm"
                      key={index}
                      onClick={() => {
                        setCurrentItem(em);
                      }}
                      style={
                        currentItem && currentItem.ID == em.ID
                          ? { background: "#F61A41" }
                          : {}
                      }
                    >
                      <div
                        className="itmImg"
                        style={{ background: `url(${em.Image})` }}
                      ></div>
                      <article>
                        <img src={em.Logo || logo} alt="" />
                        <p
                          style={
                            currentItem && currentItem.ID == em.ID
                              ? { color: "#fff" }
                              : {}
                          }
                        >
                          {em.Name}
                        </p>
                      </article>
                    </div>
                  );
                })
              : sales && sales.length > 0 && admin.isAdmin == "0"
              ? sales.map((em, index) => {
                  if (
                    checkName &&
                    checkName.length > 0 &&
                    checkName.includes(em.Name)
                  ) {
                    return (
                      <div
                        className="actionItemAdm"
                        key={index}
                        onClick={() => {
                          setCurrentItem(em);
                        }}
                        style={
                          currentItem && currentItem.ID == em.ID
                            ? { background: "#F61A41" }
                            : {}
                        }
                      >
                        <div
                          className="itmImg"
                          style={{ background: `url(${em.Image})` }}
                        ></div>
                        <article>
                          <img src={em.Logo || logo} alt="" />
                          <p
                            style={
                              currentItem && currentItem.ID == em.ID
                                ? { color: "#fff" }
                                : {}
                            }
                          >
                            {em.Name}
                          </p>
                        </article>
                      </div>
                    );
                  }
                })
              : ""}
          </div>
          <div className="actionsRight">
            <h3>
              <span
                style={!change ? { color: "#F61A41" } : {}}
                onClick={() => {
                  setCurrentItem(null);
                }}
              >
                Добавление /
              </span>
              <span style={change ? { color: "#F61A41" } : {}}> Изменение</span>
            </h3>
            <p>Заполните поля и сохраните изменения</p>
            {change ? (
              <div className="actionsForm">
                <article>Выберите магазин из списка</article>
                <Select
                  value={
                    shops &&
                    shops.length > 0 &&
                    currentItem &&
                    shops.find((em) => {
                      return em.value == currentItem.Name;
                    })
                  }
                  onChange={handleChangeName2}
                  name="Name"
                  options={shops}
                />

                <div className="flexInputs">
                  <div>
                    <article>Дата от</article>
                    <input
                      type="date"
                      value={currentItem ? currentItem.TimeBegin : ""}
                      onChange={(e) => handleChangeCurrent(e.target.name, e)}
                      placeholder="Срок действия"
                      name="TimeBegin"
                    />
                  </div>
                  <div>
                    <article>Дата до</article>
                    <input
                      type="date"
                      value={currentItem ? currentItem.TimeOfEnd : ""}
                      onChange={(e) => handleChangeCurrent(e.target.name, e)}
                      placeholder="Срок действия"
                      name="TimeOfEnd"
                    />
                  </div>
                </div>
                <input
                  type="text"
                  value={currentItem ? currentItem.Title : ""}
                  onChange={(e) => handleChangeCurrent(e.target.name, e)}
                  placeholder="Заголовок"
                  name="Title"
                />
                <textarea
                  name="Description"
                  rows={12}
                  value={currentItem ? currentItem.Description : ""}
                  onChange={(e) => handleChangeCurrent(e.target.name, e)}
                  placeholder="Описание"
                  id=""
                ></textarea>
                <FileUploader
                  handleChange={handleChangeFile}
                  name="file"
                  label="Загрузите или перетащите файл"
                  types={fileTypes}
                />
                <div className="buttonsSett">
                  <div
                    className="saveButton"
                    onClick={() => {
                      let surname = new FormData();
                      surname.append("name", currentItem.Name);
                      fetch("https://domcash.ru/api/shop/pathShop.php", {
                        method: "POST",
                        body: surname,
                      })
                        .then((response) => {
                          return response.json();
                        })
                        .then((logo) => {
                          changeSale(currentItem, logo[0].Image).then(
                            (status) => {
                              if (status == 200) {
                                Store.addNotification({
                                  title: "Акции",
                                  message: `Акция успешно изменена`,
                                  type: "success",
                                  insert: "top",
                                  container: "top-right",
                                  animationIn: [
                                    "animate__animated",
                                    "animate__bounceIn",
                                  ],
                                  animationOut: [
                                    "animate__animated",
                                    "animate__backOutUp",
                                  ],
                                  dismiss: {
                                    duration: 3000,
                                    onScreen: true,
                                  },
                                });
                                setCurrentItem(null);
                                setFile(null);
                                getSales().then((data) => {
                                  setSales(data);
                                });
                              }
                            }
                          );
                        });
                    }}
                  >
                    Сохранить
                  </div>
                  <div className="closeButton" onClick={() => setPop(true)}>
                    Удалить
                  </div>
                </div>
              </div>
            ) : (
              <div className="actionsForm">
                <article>Выберите магазин из списка</article>
                <Select
                  value={name}
                  onChange={handleChangeName}
                  options={shops}
                  placeholder="Выбрать"
                />
                <div className="flexInputs">
                  <div>
                    <article>Дата от</article>
                    <input
                      type="date"
                      value={timeBefore}
                      onChange={(e) => setTimeBefore(e.target.value)}
                      placeholder="Срок действия"
                    />
                  </div>
                  <div>
                    <article>Дата до</article>
                    <input
                      type="date"
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                      placeholder="Срок действия"
                    />
                  </div>
                </div>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Заголовок"
                />
                <textarea
                  name=""
                  rows={12}
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                  placeholder="Описание"
                  id=""
                ></textarea>

                <FileUploader
                  handleChange={handleChange}
                  name="file"
                  label="Загрузите или перетащите файл"
                  types={fileTypes}
                />
                <div className="buttonsSett">
                  <div className="saveButton" onClick={() => save()}>
                    Добавить
                  </div>
                  <div className="closeButton">Отменить</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";
import AdminHeader from "../../../Components/AdminHeader";
import AdminMenu from "../../../Components/AdminMenu";
import "./style.css";
import {
  changePartner,
  deletePartner,
  getCategoriesAdmin,
  updateLogoPartner,
} from "../../../Api/Api";
import { routes } from "../../../routes";
import { useNavigate } from "react-router-dom";
import { Store } from "react-notifications-component";
import { FileUploader } from "react-drag-drop-files";
import ReactInputMask from "react-input-mask";
import Select from "react-select/base";
import SelectComponent from "../../../Components/SelectComponent";
export default function AdminPartnerData() {
  let params = new URLSearchParams(document.location.search);
  const [data, setData] = useState(JSON.parse(params.get("key")));
  const [pop, setPop] = useState(false);
  const [cats, setCats] = useState([]);

  useEffect(() => {
    getCategoriesAdmin().then((data) => {
      setCats(data);
    });
  }, []);
  const changeRubrik = (value) => {
    setData({ ...data, category: value });
  };

  const Navigate = useNavigate();
  const fileTypes = ["JPG", "PNG", "WEBP", "GIF"];
  const handleChange = (name, e) => {
    setData({ ...data, [name]: e.target.value });
  };
  const [file, setFile] = useState(null);
  const handleChangeFile = (file) => {
    const data1 = new FormData();

    data1.append("file", file);
    fetch("https://domcash.ru/avatar.php", {
      method: "post",
      body: data1,
    })
      .then((response) => {
        return response.json();
      })
      .then((path) => {
        updateLogoPartner(path, data.ID, data.Name).then((status) => {
          if (status == 200) {
            Store.addNotification({
              title: "Логотип",
              message: `Логотип партнера изменен`,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__bounceIn"],
              animationOut: ["animate__animated", "animate__backOutUp"],
              dismiss: {
                duration: 3000,
                onScreen: true,
              },
            });
          }
        });
      });
  };
  return (
    <div className="cont">
      {pop && (
        <div className="deleteMe">
          <div className="deletMeBody">
            <h3>
              Вы действительно хотите удалить парнера - {data && data.Name}?
            </h3>
            <div>
              <div
                className="saveData"
                onClick={() => {
                  setPop(false);
                }}
              >
                Отменить
              </div>
              <div
                className="backData"
                onClick={() =>
                  deletePartner(data.ID).then((status) => {
                    if (status == 200) {
                      Navigate(routes.adminPartners);
                      Store.addNotification({
                        title: "Партнеры",
                        message: `Партнер успешно удален`,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__bounceIn"],
                        animationOut: [
                          "animate__animated",
                          "animate__backOutUp",
                        ],
                        dismiss: {
                          duration: 3000,
                          onScreen: true,
                        },
                      });
                    }
                  })
                }
              >
                Удалить
              </div>
            </div>
          </div>
        </div>
      )}
      <AdminMenu></AdminMenu>
      <div className="mainCont">
        <AdminHeader></AdminHeader>
        <h3 className="partnerDataTitle">Партнер - {data.Name}</h3>
        <div className="adminPartnerData">
          <div className="leftTable">
            <div className="ltdTitle" style={{ background: "#474753" }}>
              Для клиента
            </div>
            <div className="ltdRow">
              <p>Название</p>
              <input
                type="text"
                value={data.Name}
                name="Name"
                onChange={(e) => handleChange(e.target.name, e)}
                placeholder="Введите текст..."
              />
            </div>
            <div className="ltdRow">
              <p>Логотип</p>
              <FileUploader
                handleChange={handleChangeFile}
                name="file"
                label="Загрузите или перетащите файл"
                types={fileTypes}
              />
            </div>
            <div className="ltdRow">
              <p>Рубрика</p>
              {/*    <input
                type="text"
                value={data.category}
                name="category"
                placeholder="Введите текст..."
                onChange={(e) => handleChange(e.target.name, e)}
              /> */}
              <SelectComponent
                select={cats}
                setSelect={changeRubrik}
                currentSelect={data.category}
              ></SelectComponent>
            </div>
            <div className="ltdRow">
              <p>% начисления пользователю</p>
              <input
                type="text"
                value={data.SaleForClients}
                placeholder="Введите текст..."
                name="SaleForClients"
                onChange={(e) => handleChange(e.target.name, e)}
              />
            </div>
            <div className="ltdRow">
              <p>Телефон</p>

              <ReactInputMask
                name="Tel"
                placeholder="Введите текст..."
                mask="+9(999)999 99 99"
                value={data.Tel}
                onChange={(e) => handleChange(e.target.name, e)}
              ></ReactInputMask>
            </div>
            <div className="ltdRow">
              <p>Вебсайт</p>
              <input
                type="text"
                onChange={(e) => handleChange(e.target.name, e)}
                name="WebSyte"
                value={data.WebSyte}
                placeholder="Введите текст..."
              />
            </div>
            <div className="ltdRow">
              <p>Адрес</p>
              <input
                type="text"
                value={data.City}
                name="City"
                onChange={(e) => handleChange(e.target.name, e)}
                placeholder="Введите текст..."
              />
            </div>
            <div className="ltdRow">
              <p>График работы</p>
              <input
                type="text"
                name="WorkSchedule"
                onChange={(e) => handleChange(e.target.name, e)}
                value={data.WorkSchedule}
                placeholder="Введите текст..."
              />
            </div>
          </div>
          <div className="rightTable">
            <div className="ltdTitle" style={{ background: "#F61A41" }}>
              Для Админа
            </div>
            <div className="ltdRow">
              <p>Юридическое наименование</p>
              <input
                type="text"
                value={data.UrName}
                placeholder="Введите текст..."
                name="UrName"
                onChange={(e) => handleChange(e.target.name, e)}
              />
            </div>
            <div className="ltdRow">
              <p>ИНН</p>
              <input
                type="text"
                value={data.INN}
                name="INN"
                onChange={(e) => handleChange(e.target.name, e)}
                placeholder="Введите текст..."
              />
            </div>
            <div className="ltdRow">
              <p>Реквизиты ЮЛ</p>
              <input
                type="text"
                name="RecUl"
                onChange={(e) => handleChange(e.target.name, e)}
                value={data.RecUl}
                placeholder="Введите текст..."
              />
            </div>
            <div className="ltdRow">
              <p>% начисления АВ</p>
              <input
                type="text"
                onChange={(e) => handleChange(e.target.name, e)}
                name="AgencySumm"
                value={data.AgencySumm}
                placeholder="Введите текст..."
              />
            </div>
            <div className="ltdRow">
              <p>Телефон</p>

              <ReactInputMask
                name="TelForAdmin"
                placeholder="Введите текст..."
                mask="+9(999)999 99 99"
                value={data.TelForAdmin}
                onChange={(e) => handleChange(e.target.name, e)}
              ></ReactInputMask>
            </div>
            <div className="ltdRow">
              <p>Вебсайт</p>
              <input
                type="text"
                value={data.WebSyteForAdmin}
                placeholder="Введите текст..."
                onChange={(e) => handleChange(e.target.name, e)}
                name="WebSyteForAdmin"
              />
            </div>
            <div className="ltdRow">
              <p>Фискальный накопители</p>
              <input
                type="text"
                value={data.FeskScore}
                placeholder="Введите текст..."
                onChange={(e) => handleChange(e.target.name, e)}
                name="FeskScore"
              />
            </div>
            <div className="ltdRow">
              <p>Контакт для связи</p>
              <input
                type="text"
                value={data.Contacts}
                onChange={(e) => handleChange(e.target.name, e)}
                name="Contacts"
                placeholder="Введите текст..."
              />
            </div>
            <div className="ltdRow">
              <p>Лимит суммы</p>
              <input
                type="text"
                value={data.MaxSumm}
                onChange={(e) => handleChange(e.target.name, e)}
                name="MaxSumm"
                placeholder="Введите текст..."
              />
            </div>
          </div>
        </div>
        <div className="areaCont">
          <textarea
            className="partComment"
            name="Description"
            value={data.Description}
            onChange={(e) => handleChange(e.target.name, e)}
            placeholder="Комментарий"
            rows={6}
            id=""
          ></textarea>
        </div>
        <div className="partnerDataButtons">
          <div
            className="saveData"
            onClick={() => {
              changePartner(data).then((status) => {
                if (status == 200) {
                  Store.addNotification({
                    title: "Партнеры",
                    message: `Партнер успешно изменен`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__bounceIn"],
                    animationOut: ["animate__animated", "animate__backOutUp"],
                    dismiss: {
                      duration: 3000,
                      onScreen: true,
                    },
                  });
                  Navigate(routes.adminPartners);
                }
              });
            }}
          >
            Сохранить
          </div>
          <div className="backData" onClick={() => setPop(true)}>
            Удалить
          </div>
        </div>
      </div>
    </div>
  );
}

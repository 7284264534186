import "./style.css";
import logo from "./img/logo.svg";
import Select from "react-select";
import burger from "./img/menu.svg";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { UserContext } from "../../Context/user";
import { getCity } from "../../Api/Api";
import { CityContext } from "../../Context/city";
import { Fade } from "react-reveal";
import Notice from "../../Func/Func";

export default function Header() {
  const [open, isOpen] = useState(false);
  const Navigate = useNavigate();
  const [user, setUser] = useContext(UserContext);

  const [options, setOptions] = useState([]);
  const [currentCity, setCurrentCity] = useContext(CityContext);

  useEffect(() => {
    if (user && user.Adress) {
      let adress = JSON.parse(user.Adress).data.city;

      // setCurrentCity(adress.data.)
    }
  }, [user]);

  useEffect(() => {
    getCity().then((data) => {
      setOptions(
        data.map((em) => {
          return {
            value: em.City,
            label: em.City,
          };
        })
      );
    });
  }, []);
  return (
    <Fade top duration={500}>
      <div className="header">
        <div
          className="popHeader"
          style={
            !open
              ? {
                  transform: "translateY(-100vh)",
                }
              : {
                  transform: "translateY(0)",
                }
          }
        >
          <ul>
            <li
              onClick={() => {
                Navigate(routes.main);
                isOpen(false);
              }}
            >
              Главная
            </li>
            <li
              onClick={() => {
                Navigate(routes.profile);
                isOpen(false);
              }}
            >
              Мой профиль
            </li>
            <li
              onClick={() => {
                Navigate(routes.questions);
                isOpen(false);
              }}
            >
              Частые вопросы
            </li>
            <li
              onClick={() => {
                Navigate(routes.main);
                isOpen(false);
              }}
            >
              Оферта
            </li>
            <li
              onClick={() => {
                localStorage.clear();
                window.location.pathname = routes.auth;
                isOpen(false);
              }}
            >
              Выход
            </li>
          </ul>
          <p>Версия приложения 1.02</p>
        </div>

        <div className="openHead">
          <img
            src="/img/logo.svg"
            alt=""
            className="hdLogo"
            onClick={() => Navigate(routes.main)}
          />
          <div className="city">
            {options && options.length > 0 && currentCity && (
              <Select
                options={options}
                defaultValue={currentCity}
                onChange={setCurrentCity}
                placeholder="Выбрать город"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "55vw",
                    border: "none",
                    outline: "none",
                    color: "#8A8E9E !important",
                    borderRadius: "1vw",
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "#F61A41",
                    primary: "#F61A41",
                  },
                })}
              />
            )}
          </div>
          <img src={burger} alt="" onClick={() => isOpen(!open)} />
        </div>
      </div>
    </Fade>
  );
}

import { useContext, useEffect, useState } from "react";
import "./style.css";
import { UserContext } from "../../Context/user";
import { AddressSuggestions } from "react-dadata";
import { updateUser } from "../../Api/Api";
import ReactInputMask from "react-input-mask";
import Notice from "../../Func/Func";
export default function Profile() {
  const [user, setUser] = useContext(UserContext);
  const [active, setActive] = useState(
    user && user.Sex == "Женский" ? true : false
  );

  const handleChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };
  const [newAdress, setNewAdress] = useState(
    user ? JSON.parse(user.Adress) : ""
  );
  useEffect(() => {
    setUser({ ...user, Adress: JSON.stringify(newAdress) });
  }, [newAdress]);
  useEffect(() => {
    if (active) {
      setUser({ ...user, Sex: "Женский" });
    } else {
      setUser({ ...user, Sex: "Мужской" });
    }
  }, [active]);
  return (
    <div className="profile">
      <div className="nav" onClick={() => window.history.back()}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 7L9 13L15 19"
            stroke="#474753"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p>Мой профиль</p>
      </div>
      <div className="inpChange">
        <div>
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="28" height="28" rx="8" fill="#DDDFE6" />
            <path
              d="M13.4974 12.737C15.3577 12.737 16.8659 11.2288 16.8659 9.36848C16.8659 7.50812 15.3577 6 13.4974 6C11.637 6 10.1289 7.50812 10.1289 9.36848C10.1289 11.2288 11.637 12.737 13.4974 12.737Z"
              fill="#8A8E9E"
            />
            <path
              d="M19.3951 17.7897C19.3951 20.1149 19.3951 22.0003 13.5003 22.0003C7.60547 22.0003 7.60547 20.1149 7.60547 17.7897C7.60547 15.4645 10.2449 13.5791 13.5003 13.5791C16.7557 13.5791 19.3951 15.4645 19.3951 17.7897Z"
              fill="#8A8E9E"
            />
          </svg>
          <p>Полное имя</p>
        </div>
        <input
          type="text"
          value={user ? user.FIO : ""}
          placeholder="Фамилия имя отчество"
          name="FIO"
          onChange={handleChange}
        />
      </div>
      <div className="inpChange">
        <div>
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="28" height="28" rx="8" fill="#DDDFE6" />
            <path
              d="M16.9632 16.1234L16.584 16.5234C16.584 16.5234 15.6815 17.4725 13.219 14.88C10.7565 12.2875 11.659 11.3384 11.659 11.3384L11.8982 11.0859C12.4865 10.4659 12.5424 9.47085 12.0282 8.74419L10.9782 7.25835C10.3415 6.35835 9.11238 6.24002 8.38321 7.00752L7.07571 8.38335C6.71488 8.76419 6.47321 9.25669 6.50238 9.80419C6.57738 11.2042 7.17571 14.215 10.5124 17.7292C14.0515 21.4542 17.3724 21.6025 18.7299 21.4684C19.1599 21.4267 19.5332 21.1942 19.834 20.8775L21.0174 19.6309C21.8174 18.7892 21.5924 17.3475 20.569 16.7592L18.9774 15.8425C18.3057 15.4567 17.4882 15.57 16.9632 16.1225M15.0499 5.56669C15.0767 5.4031 15.1673 5.25683 15.3018 5.15997C15.4363 5.0631 15.6037 5.02356 15.7674 5.05002C15.7882 5.05419 15.8565 5.06669 15.8924 5.07502C15.9632 5.09002 16.0624 5.11502 16.1857 5.15002C16.4332 5.22252 16.779 5.34169 17.194 5.53169C18.024 5.91252 19.1282 6.57835 20.2749 7.72419C21.4215 8.87085 22.0874 9.97585 22.4682 10.8059C22.6582 11.2209 22.7765 11.5659 22.849 11.8142C22.8856 11.939 22.917 12.0652 22.9432 12.1925L22.9474 12.2184C22.9748 12.3834 22.9363 12.5526 22.8401 12.6895C22.7439 12.8263 22.5978 12.9199 22.4332 12.95C22.27 12.9765 22.103 12.9373 21.9687 12.8409C21.8344 12.7446 21.7437 12.5989 21.7165 12.4359C21.6995 12.3438 21.677 12.2528 21.649 12.1634C21.5639 11.8772 21.4581 11.5975 21.3324 11.3267C21.0074 10.6184 20.4224 9.64002 19.3907 8.60835C18.3599 7.57669 17.3815 6.99252 16.6724 6.66752C16.4018 6.54187 16.1225 6.43603 15.8365 6.35085C15.7489 6.32611 15.6605 6.30388 15.5715 6.28419C15.4079 6.25693 15.2614 6.16644 15.1638 6.03222C15.0663 5.898 15.0253 5.7308 15.0499 5.56669Z"
              fill="#8A8E9E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.2391 8.44174C15.2615 8.36277 15.2993 8.28901 15.3503 8.22465C15.4013 8.1603 15.4645 8.10663 15.5362 8.06669C15.608 8.02676 15.6869 8.00136 15.7684 7.99193C15.85 7.9825 15.9326 7.98923 16.0116 8.01174L16.0141 8.01258L16.0166 8.01341L16.0232 8.01508L16.0399 8.02008L16.0866 8.03674C16.308 8.12206 16.5219 8.22605 16.7257 8.34758C17.1332 8.58924 17.6899 8.98924 18.3441 9.64424C18.9982 10.2984 19.3991 10.8551 19.6407 11.2626C19.7616 11.4667 19.8416 11.6326 19.8932 11.7542C19.9209 11.8179 19.946 11.8827 19.9682 11.9484L19.9724 11.9651L19.9749 11.9717V11.9742L19.9757 11.9751C19.9757 11.9751 19.9757 11.9767 19.3757 12.1484L19.9757 11.9767C20.0181 12.1344 19.997 12.3024 19.9171 12.4448C19.8371 12.5871 19.7046 12.6926 19.548 12.7385C19.3913 12.7844 19.2229 12.7673 19.0787 12.6906C18.9346 12.614 18.8261 12.484 18.7766 12.3284L18.7741 12.3201C18.7155 12.175 18.6458 12.0345 18.5657 11.9001C18.3791 11.5859 18.0432 11.1109 17.4599 10.5276C16.8766 9.94424 16.4024 9.60924 16.0882 9.42341C15.9538 9.3433 15.8134 9.27365 15.6682 9.21508L15.6599 9.21174C15.5025 9.16442 15.37 9.05698 15.2913 8.9127C15.2125 8.76842 15.1937 8.59975 15.2391 8.44174Z"
              fill="#8A8E9E"
            />
          </svg>

          <p>Телефон</p>
        </div>

        <ReactInputMask
          name="Phone"
          placeholder="Телефон"
          mask="+7(999)999 99 99"
          value={user ? user.Phone : ""}
          onChange={handleChange}
        ></ReactInputMask>
      </div>
      <div className="inpChange">
        <div>
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="28" height="28" rx="8" fill="#DDDFE6" />
            <path
              d="M13.4974 12.737C15.3577 12.737 16.8659 11.2288 16.8659 9.36848C16.8659 7.50812 15.3577 6 13.4974 6C11.637 6 10.1289 7.50812 10.1289 9.36848C10.1289 11.2288 11.637 12.737 13.4974 12.737Z"
              fill="#8A8E9E"
            />
            <path
              d="M19.3951 17.7897C19.3951 20.1149 19.3951 22.0003 13.5003 22.0003C7.60547 22.0003 7.60547 20.1149 7.60547 17.7897C7.60547 15.4645 10.2449 13.5791 13.5003 13.5791C16.7557 13.5791 19.3951 15.4645 19.3951 17.7897Z"
              fill="#8A8E9E"
            />
          </svg>

          <p>Email</p>
        </div>
        <input
          value={user ? user.Email : ""}
          type="text"
          placeholder="mail@mail.ru"
          name="Email"
          onChange={handleChange}
        />
      </div>
      <div className="inpChange">
        <div>
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="28" height="28" rx="8" fill="#DDDFE6" />
            <path
              d="M13.4974 12.737C15.3577 12.737 16.8659 11.2288 16.8659 9.36848C16.8659 7.50812 15.3577 6 13.4974 6C11.637 6 10.1289 7.50812 10.1289 9.36848C10.1289 11.2288 11.637 12.737 13.4974 12.737Z"
              fill="#8A8E9E"
            />
            <path
              d="M19.3951 17.7897C19.3951 20.1149 19.3951 22.0003 13.5003 22.0003C7.60547 22.0003 7.60547 20.1149 7.60547 17.7897C7.60547 15.4645 10.2449 13.5791 13.5003 13.5791C16.7557 13.5791 19.3951 15.4645 19.3951 17.7897Z"
              fill="#8A8E9E"
            />
          </svg>
          <p>День рождения</p>
        </div>
        <input
          type="text"
          name="Birthday"
          value={user ? user.Birthday : ""}
          onChange={handleChange}
          placeholder="xx.xx.xxxx"
        />
      </div>
      <div className="inpChange">
        <div>
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="28" height="28" rx="8" fill="#DDDFE6" />
            <path
              d="M13.4974 12.737C15.3577 12.737 16.8659 11.2288 16.8659 9.36848C16.8659 7.50812 15.3577 6 13.4974 6C11.637 6 10.1289 7.50812 10.1289 9.36848C10.1289 11.2288 11.637 12.737 13.4974 12.737Z"
              fill="#8A8E9E"
            />
            <path
              d="M19.3951 17.7897C19.3951 20.1149 19.3951 22.0003 13.5003 22.0003C7.60547 22.0003 7.60547 20.1149 7.60547 17.7897C7.60547 15.4645 10.2449 13.5791 13.5003 13.5791C16.7557 13.5791 19.3951 15.4645 19.3951 17.7897Z"
              fill="#8A8E9E"
            />
          </svg>
          <p>Пол</p>
        </div>
        <div className="polChange">
          <p
            onClick={() => setActive(true)}
            style={active ? { background: "#F61A41", color: "#fff" } : {}}
          >
            Женский
          </p>
          <p
            onClick={() => setActive(false)}
            style={!active ? { background: "#F61A41", color: "#fff" } : {}}
          >
            Мужской
          </p>
        </div>
      </div>
      <div className="inpChange">
        <div>
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="28" height="28" rx="8" fill="#DDDFE6" />
            <path
              d="M21.4174 10.3789C21.4999 11.3247 21.4999 12.5044 21.4999 13.9999C21.4999 17.1004 21.4999 18.8412 20.7597 19.9632L16.2949 15.4999L21.4174 10.3789ZM19.9639 20.7589L15.4999 16.2949L10.3789 21.4174C11.3247 21.4999 12.5044 21.4999 13.9999 21.4999C17.1004 21.4999 18.8419 21.4999 19.9639 20.7589Z"
              fill="#8A8E9E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14 6.5C17.5355 6.5 19.3033 6.5 20.4012 7.598C20.7793 7.976 21.0275 8.4335 21.1903 9.01475L9.01475 21.1903C8.4335 21.0275 7.976 20.7793 7.598 20.4012C6.5 19.304 6.5 17.5355 6.5 14C6.5 10.4645 6.5 8.69675 7.598 7.598C8.6975 6.5 10.4645 6.5 14 6.5ZM9.125 11.5677C9.125 12.9065 9.96275 14.4688 11.27 15.0275C11.5745 15.1572 11.9255 15.1572 12.23 15.0275C13.5373 14.4688 14.375 12.9065 14.375 11.5677C14.375 10.2185 13.1997 9.125 11.75 9.125C10.3003 9.125 9.125 10.2185 9.125 11.5677Z"
              fill="#8A8E9E"
            />
            <path
              d="M12.875 11.75C12.875 12.0484 12.7565 12.3345 12.5455 12.5455C12.3345 12.7565 12.0484 12.875 11.75 12.875C11.4516 12.875 11.1655 12.7565 10.9545 12.5455C10.7435 12.3345 10.625 12.0484 10.625 11.75C10.625 11.4516 10.7435 11.1655 10.9545 10.9545C11.1655 10.7435 11.4516 10.625 11.75 10.625C12.0484 10.625 12.3345 10.7435 12.5455 10.9545C12.7565 11.1655 12.875 11.4516 12.875 11.75Z"
              fill="#8A8E9E"
            />
          </svg>

          <p>Адрес</p>
        </div>

        <AddressSuggestions
          token="307eb1b5e51c6846adac5e5c92e1b3a5de831912"
          value={newAdress}
          onChange={setNewAdress}
          inputProps={{ placeholder: "Адрес" }}
        />
      </div>
      <div className="inpChange">
        <div>
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="28" height="28" rx="8" fill="#DDDFE6" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21.5845 10.6698C21.5386 10.6665 21.4878 10.6665 21.4345 10.6665H19.3303C17.607 10.6665 16.1328 12.0232 16.1328 13.7915C16.1328 15.5598 17.6078 16.9165 19.3303 16.9165H21.4345C21.4878 16.9165 21.5386 16.9165 21.5861 16.9132C21.9403 16.8918 22.2747 16.7424 22.5269 16.4928C22.7791 16.2432 22.9319 15.9105 22.957 15.5565C22.9603 15.5065 22.9603 15.4523 22.9603 15.4023V12.1807C22.9603 12.1307 22.9603 12.0765 22.957 12.0265C22.9319 11.6726 22.7791 11.3398 22.5269 11.0902C22.2747 10.8406 21.9403 10.6912 21.5861 10.6698M19.1461 14.6248C19.5895 14.6248 19.9486 14.2515 19.9486 13.7915C19.9486 13.3315 19.5895 12.9582 19.1461 12.9582C18.702 12.9582 18.3428 13.3315 18.3428 13.7915C18.3428 14.2515 18.702 14.6248 19.1461 14.6248Z"
              fill="#8A8E9E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21.433 18.1667C21.4618 18.1655 21.4905 18.1712 21.5167 18.1832C21.5429 18.1952 21.5659 18.2132 21.5838 18.2358C21.6018 18.2583 21.6141 18.2848 21.6199 18.3131C21.6257 18.3413 21.6248 18.3705 21.6171 18.3983C21.4505 18.9917 21.1846 19.4983 20.7588 19.9233C20.1346 20.5483 19.3438 20.8242 18.3671 20.9558C17.4171 21.0833 16.2046 21.0833 14.673 21.0833H12.913C11.3813 21.0833 10.168 21.0833 9.2188 20.9558C8.24214 20.8242 7.4513 20.5475 6.82714 19.9242C6.2038 19.3 5.92714 18.5092 5.79547 17.5325C5.66797 16.5825 5.66797 15.37 5.66797 13.8383V13.745C5.66797 12.2133 5.66797 11 5.79547 10.05C5.92714 9.07333 6.2038 8.2825 6.82714 7.65833C7.4513 7.035 8.24214 6.75833 9.2188 6.62667C10.1688 6.5 11.3813 6.5 12.913 6.5H14.673C16.2046 6.5 17.418 6.5 18.3671 6.6275C19.3438 6.75917 20.1346 7.03583 20.7588 7.65917C21.1846 8.08583 21.4505 8.59167 21.6171 9.185C21.6248 9.21281 21.6257 9.24202 21.6199 9.27027C21.6141 9.29851 21.6018 9.325 21.5838 9.34757C21.5659 9.37014 21.5429 9.38816 21.5167 9.40016C21.4905 9.41217 21.4618 9.41782 21.433 9.41667H19.3296C16.9655 9.41667 14.8821 11.2833 14.8821 13.7917C14.8821 16.3 16.9655 18.1667 19.3296 18.1667H21.433ZM8.79297 9.83333C8.62721 9.83333 8.46824 9.89918 8.35103 10.0164C8.23382 10.1336 8.16797 10.2926 8.16797 10.4583C8.16797 10.6241 8.23382 10.7831 8.35103 10.9003C8.46824 11.0175 8.62721 11.0833 8.79297 11.0833H12.1263C12.2921 11.0833 12.451 11.0175 12.5682 10.9003C12.6855 10.7831 12.7513 10.6241 12.7513 10.4583C12.7513 10.2926 12.6855 10.1336 12.5682 10.0164C12.451 9.89918 12.2921 9.83333 12.1263 9.83333H8.79297Z"
              fill="#8A8E9E"
            />
          </svg>

          <p>Лицевой счет УК</p>
        </div>
        <input
          type="text"
          name="FaceNumber"
          value={user ? user.FaceNumber : ""}
          onChange={handleChange}
          placeholder="Лицевой счет"
        />
      </div>
      <div className="inpChange">
        <div>
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="28" height="28" rx="8" fill="#DDDFE6" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7 10.3967C7 9.04489 7 8.36896 7.26309 7.85262C7.49451 7.39844 7.86377 7.02917 8.31795 6.79776C8.83429 6.53467 9.51022 6.53467 10.8621 6.53467H17.1379C18.4898 6.53467 19.1657 6.53467 19.682 6.79776C20.1362 7.02917 20.5055 7.39844 20.7369 7.85262C21 8.36896 21 9.04489 21 10.3967V17.6381C21 18.99 21 19.6659 20.7369 20.1822C20.5055 20.6364 20.1362 21.0057 19.682 21.2371C19.1657 21.5002 18.4898 21.5002 17.1379 21.5002H10.8621C9.51022 21.5002 8.83429 21.5002 8.31795 21.2371C7.86377 21.0057 7.49451 20.6364 7.26309 20.1822C7 19.6659 7 18.99 7 17.6381V10.3967ZM9.41354 9.67262C9.41354 9.27269 9.73775 8.94848 10.1377 8.94848H17.8618C18.2617 8.94848 18.586 9.27269 18.586 9.67262C18.586 10.0725 18.2617 10.3968 17.8618 10.3968H10.1377C9.73775 10.3968 9.41354 10.0725 9.41354 9.67262ZM10.1377 11.8451C9.73775 11.8451 9.41354 12.1693 9.41354 12.5692C9.41354 12.9692 9.73775 13.2934 10.1377 13.2934H14.4825C14.8824 13.2934 15.2066 12.9692 15.2066 12.5692C15.2066 12.1693 14.8824 11.8451 14.4825 11.8451H10.1377ZM9.41354 15.4658C9.41354 15.0659 9.73775 14.7417 10.1377 14.7417H17.8618C18.2617 14.7417 18.586 15.0659 18.586 15.4658C18.586 15.8658 18.2617 16.19 17.8618 16.19H10.1377C9.73775 16.19 9.41354 15.8658 9.41354 15.4658ZM10.1377 17.6383C9.73775 17.6383 9.41354 17.9625 9.41354 18.3625C9.41354 18.7624 9.73775 19.0866 10.1377 19.0866H16.4135C16.8135 19.0866 17.1377 18.7624 17.1377 18.3625C17.1377 17.9625 16.8135 17.6383 16.4135 17.6383H10.1377Z"
              fill="#8A8E9E"
            />
          </svg>

          <p>Лицевой счет УК</p>
        </div>
        <input
          type="text"
          placeholder="УК"
          name="Uk"
          value={user ? user.Uk : ""}
          onChange={handleChange}
        />
      </div>
      <div className="inpChange">
        <div>
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="28" height="28" rx="8" fill="#DDDFE6" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21.5845 10.6698C21.5386 10.6665 21.4878 10.6665 21.4345 10.6665H19.3303C17.607 10.6665 16.1328 12.0232 16.1328 13.7915C16.1328 15.5598 17.6078 16.9165 19.3303 16.9165H21.4345C21.4878 16.9165 21.5386 16.9165 21.5861 16.9132C21.9403 16.8918 22.2747 16.7424 22.5269 16.4928C22.7791 16.2432 22.9319 15.9105 22.957 15.5565C22.9603 15.5065 22.9603 15.4523 22.9603 15.4023V12.1807C22.9603 12.1307 22.9603 12.0765 22.957 12.0265C22.9319 11.6726 22.7791 11.3398 22.5269 11.0902C22.2747 10.8406 21.9403 10.6912 21.5861 10.6698M19.1461 14.6248C19.5895 14.6248 19.9486 14.2515 19.9486 13.7915C19.9486 13.3315 19.5895 12.9582 19.1461 12.9582C18.702 12.9582 18.3428 13.3315 18.3428 13.7915C18.3428 14.2515 18.702 14.6248 19.1461 14.6248Z"
              fill="#8A8E9E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21.433 18.1667C21.4618 18.1655 21.4905 18.1712 21.5167 18.1832C21.5429 18.1952 21.5659 18.2132 21.5838 18.2358C21.6018 18.2583 21.6141 18.2848 21.6199 18.3131C21.6257 18.3413 21.6248 18.3705 21.6171 18.3983C21.4505 18.9917 21.1846 19.4983 20.7588 19.9233C20.1346 20.5483 19.3438 20.8242 18.3671 20.9558C17.4171 21.0833 16.2046 21.0833 14.673 21.0833H12.913C11.3813 21.0833 10.168 21.0833 9.2188 20.9558C8.24214 20.8242 7.4513 20.5475 6.82714 19.9242C6.2038 19.3 5.92714 18.5092 5.79547 17.5325C5.66797 16.5825 5.66797 15.37 5.66797 13.8383V13.745C5.66797 12.2133 5.66797 11 5.79547 10.05C5.92714 9.07333 6.2038 8.2825 6.82714 7.65833C7.4513 7.035 8.24214 6.75833 9.2188 6.62667C10.1688 6.5 11.3813 6.5 12.913 6.5H14.673C16.2046 6.5 17.418 6.5 18.3671 6.6275C19.3438 6.75917 20.1346 7.03583 20.7588 7.65917C21.1846 8.08583 21.4505 8.59167 21.6171 9.185C21.6248 9.21281 21.6257 9.24202 21.6199 9.27027C21.6141 9.29851 21.6018 9.325 21.5838 9.34757C21.5659 9.37014 21.5429 9.38816 21.5167 9.40016C21.4905 9.41217 21.4618 9.41782 21.433 9.41667H19.3296C16.9655 9.41667 14.8821 11.2833 14.8821 13.7917C14.8821 16.3 16.9655 18.1667 19.3296 18.1667H21.433ZM8.79297 9.83333C8.62721 9.83333 8.46824 9.89918 8.35103 10.0164C8.23382 10.1336 8.16797 10.2926 8.16797 10.4583C8.16797 10.6241 8.23382 10.7831 8.35103 10.9003C8.46824 11.0175 8.62721 11.0833 8.79297 11.0833H12.1263C12.2921 11.0833 12.451 11.0175 12.5682 10.9003C12.6855 10.7831 12.7513 10.6241 12.7513 10.4583C12.7513 10.2926 12.6855 10.1336 12.5682 10.0164C12.451 9.89918 12.2921 9.83333 12.1263 9.83333H8.79297Z"
              fill="#8A8E9E"
            />
          </svg>

          <p>Название УК</p>
        </div>
        <input
          type="text"
          name="UkName"
          value={user ? user.UkName : ""}
          onChange={handleChange}
          placeholder="Название УК"
        />
      </div>
      <div className="inpChange">
        <div>
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="28" height="28" rx="8" fill="#DDDFE6" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7 10.3967C7 9.04489 7 8.36896 7.26309 7.85262C7.49451 7.39844 7.86377 7.02917 8.31795 6.79776C8.83429 6.53467 9.51022 6.53467 10.8621 6.53467H17.1379C18.4898 6.53467 19.1657 6.53467 19.682 6.79776C20.1362 7.02917 20.5055 7.39844 20.7369 7.85262C21 8.36896 21 9.04489 21 10.3967V17.6381C21 18.99 21 19.6659 20.7369 20.1822C20.5055 20.6364 20.1362 21.0057 19.682 21.2371C19.1657 21.5002 18.4898 21.5002 17.1379 21.5002H10.8621C9.51022 21.5002 8.83429 21.5002 8.31795 21.2371C7.86377 21.0057 7.49451 20.6364 7.26309 20.1822C7 19.6659 7 18.99 7 17.6381V10.3967ZM9.41354 9.67262C9.41354 9.27269 9.73775 8.94848 10.1377 8.94848H17.8618C18.2617 8.94848 18.586 9.27269 18.586 9.67262C18.586 10.0725 18.2617 10.3968 17.8618 10.3968H10.1377C9.73775 10.3968 9.41354 10.0725 9.41354 9.67262ZM10.1377 11.8451C9.73775 11.8451 9.41354 12.1693 9.41354 12.5692C9.41354 12.9692 9.73775 13.2934 10.1377 13.2934H14.4825C14.8824 13.2934 15.2066 12.9692 15.2066 12.5692C15.2066 12.1693 14.8824 11.8451 14.4825 11.8451H10.1377ZM9.41354 15.4658C9.41354 15.0659 9.73775 14.7417 10.1377 14.7417H17.8618C18.2617 14.7417 18.586 15.0659 18.586 15.4658C18.586 15.8658 18.2617 16.19 17.8618 16.19H10.1377C9.73775 16.19 9.41354 15.8658 9.41354 15.4658ZM10.1377 17.6383C9.73775 17.6383 9.41354 17.9625 9.41354 18.3625C9.41354 18.7624 9.73775 19.0866 10.1377 19.0866H16.4135C16.8135 19.0866 17.1377 18.7624 17.1377 18.3625C17.1377 17.9625 16.8135 17.6383 16.4135 17.6383H10.1377Z"
              fill="#8A8E9E"
            />
          </svg>

          <p>ИНН УК</p>
        </div>
        <input
          type="text"
          placeholder="ИНН УК"
          name="UkInn"
          value={user ? user.UkInn : ""}
          onChange={handleChange}
        />
      </div>
      <div className="inpChange">
        <div>
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="28" height="28" rx="8" fill="#DDDFE6" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7 10.3967C7 9.04489 7 8.36896 7.26309 7.85262C7.49451 7.39844 7.86377 7.02917 8.31795 6.79776C8.83429 6.53467 9.51022 6.53467 10.8621 6.53467H17.1379C18.4898 6.53467 19.1657 6.53467 19.682 6.79776C20.1362 7.02917 20.5055 7.39844 20.7369 7.85262C21 8.36896 21 9.04489 21 10.3967V17.6381C21 18.99 21 19.6659 20.7369 20.1822C20.5055 20.6364 20.1362 21.0057 19.682 21.2371C19.1657 21.5002 18.4898 21.5002 17.1379 21.5002H10.8621C9.51022 21.5002 8.83429 21.5002 8.31795 21.2371C7.86377 21.0057 7.49451 20.6364 7.26309 20.1822C7 19.6659 7 18.99 7 17.6381V10.3967ZM9.41354 9.67262C9.41354 9.27269 9.73775 8.94848 10.1377 8.94848H17.8618C18.2617 8.94848 18.586 9.27269 18.586 9.67262C18.586 10.0725 18.2617 10.3968 17.8618 10.3968H10.1377C9.73775 10.3968 9.41354 10.0725 9.41354 9.67262ZM10.1377 11.8451C9.73775 11.8451 9.41354 12.1693 9.41354 12.5692C9.41354 12.9692 9.73775 13.2934 10.1377 13.2934H14.4825C14.8824 13.2934 15.2066 12.9692 15.2066 12.5692C15.2066 12.1693 14.8824 11.8451 14.4825 11.8451H10.1377ZM9.41354 15.4658C9.41354 15.0659 9.73775 14.7417 10.1377 14.7417H17.8618C18.2617 14.7417 18.586 15.0659 18.586 15.4658C18.586 15.8658 18.2617 16.19 17.8618 16.19H10.1377C9.73775 16.19 9.41354 15.8658 9.41354 15.4658ZM10.1377 17.6383C9.73775 17.6383 9.41354 17.9625 9.41354 18.3625C9.41354 18.7624 9.73775 19.0866 10.1377 19.0866H16.4135C16.8135 19.0866 17.1377 18.7624 17.1377 18.3625C17.1377 17.9625 16.8135 17.6383 16.4135 17.6383H10.1377Z"
              fill="#8A8E9E"
            />
          </svg>

          <p>Лицевой счет - электроэнергия</p>
        </div>
        <input
          type="text"
          placeholder="Электричество"
          name="FaceNumberElectr"
          value={user ? user.FaceNumberElectr : ""}
          onChange={handleChange}
        />
      </div>
      <div className="inpChange">
        <div>
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="28" height="28" rx="8" fill="#DDDFE6" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7 10.3967C7 9.04489 7 8.36896 7.26309 7.85262C7.49451 7.39844 7.86377 7.02917 8.31795 6.79776C8.83429 6.53467 9.51022 6.53467 10.8621 6.53467H17.1379C18.4898 6.53467 19.1657 6.53467 19.682 6.79776C20.1362 7.02917 20.5055 7.39844 20.7369 7.85262C21 8.36896 21 9.04489 21 10.3967V17.6381C21 18.99 21 19.6659 20.7369 20.1822C20.5055 20.6364 20.1362 21.0057 19.682 21.2371C19.1657 21.5002 18.4898 21.5002 17.1379 21.5002H10.8621C9.51022 21.5002 8.83429 21.5002 8.31795 21.2371C7.86377 21.0057 7.49451 20.6364 7.26309 20.1822C7 19.6659 7 18.99 7 17.6381V10.3967ZM9.41354 9.67262C9.41354 9.27269 9.73775 8.94848 10.1377 8.94848H17.8618C18.2617 8.94848 18.586 9.27269 18.586 9.67262C18.586 10.0725 18.2617 10.3968 17.8618 10.3968H10.1377C9.73775 10.3968 9.41354 10.0725 9.41354 9.67262ZM10.1377 11.8451C9.73775 11.8451 9.41354 12.1693 9.41354 12.5692C9.41354 12.9692 9.73775 13.2934 10.1377 13.2934H14.4825C14.8824 13.2934 15.2066 12.9692 15.2066 12.5692C15.2066 12.1693 14.8824 11.8451 14.4825 11.8451H10.1377ZM9.41354 15.4658C9.41354 15.0659 9.73775 14.7417 10.1377 14.7417H17.8618C18.2617 14.7417 18.586 15.0659 18.586 15.4658C18.586 15.8658 18.2617 16.19 17.8618 16.19H10.1377C9.73775 16.19 9.41354 15.8658 9.41354 15.4658ZM10.1377 17.6383C9.73775 17.6383 9.41354 17.9625 9.41354 18.3625C9.41354 18.7624 9.73775 19.0866 10.1377 19.0866H16.4135C16.8135 19.0866 17.1377 18.7624 17.1377 18.3625C17.1377 17.9625 16.8135 17.6383 16.4135 17.6383H10.1377Z"
              fill="#8A8E9E"
            />
          </svg>

          <p>Лицевой счет газ</p>
        </div>
        <input
          type="text"
          placeholder="Газ"
          name="FaceNumberGas"
          value={user ? user.FaceNumberGas : ""}
          onChange={handleChange}
        />
      </div>
      <div className="inpChange">
        <div>
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="28" height="28" rx="8" fill="#DDDFE6" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7 10.3967C7 9.04489 7 8.36896 7.26309 7.85262C7.49451 7.39844 7.86377 7.02917 8.31795 6.79776C8.83429 6.53467 9.51022 6.53467 10.8621 6.53467H17.1379C18.4898 6.53467 19.1657 6.53467 19.682 6.79776C20.1362 7.02917 20.5055 7.39844 20.7369 7.85262C21 8.36896 21 9.04489 21 10.3967V17.6381C21 18.99 21 19.6659 20.7369 20.1822C20.5055 20.6364 20.1362 21.0057 19.682 21.2371C19.1657 21.5002 18.4898 21.5002 17.1379 21.5002H10.8621C9.51022 21.5002 8.83429 21.5002 8.31795 21.2371C7.86377 21.0057 7.49451 20.6364 7.26309 20.1822C7 19.6659 7 18.99 7 17.6381V10.3967ZM9.41354 9.67262C9.41354 9.27269 9.73775 8.94848 10.1377 8.94848H17.8618C18.2617 8.94848 18.586 9.27269 18.586 9.67262C18.586 10.0725 18.2617 10.3968 17.8618 10.3968H10.1377C9.73775 10.3968 9.41354 10.0725 9.41354 9.67262ZM10.1377 11.8451C9.73775 11.8451 9.41354 12.1693 9.41354 12.5692C9.41354 12.9692 9.73775 13.2934 10.1377 13.2934H14.4825C14.8824 13.2934 15.2066 12.9692 15.2066 12.5692C15.2066 12.1693 14.8824 11.8451 14.4825 11.8451H10.1377ZM9.41354 15.4658C9.41354 15.0659 9.73775 14.7417 10.1377 14.7417H17.8618C18.2617 14.7417 18.586 15.0659 18.586 15.4658C18.586 15.8658 18.2617 16.19 17.8618 16.19H10.1377C9.73775 16.19 9.41354 15.8658 9.41354 15.4658ZM10.1377 17.6383C9.73775 17.6383 9.41354 17.9625 9.41354 18.3625C9.41354 18.7624 9.73775 19.0866 10.1377 19.0866H16.4135C16.8135 19.0866 17.1377 18.7624 17.1377 18.3625C17.1377 17.9625 16.8135 17.6383 16.4135 17.6383H10.1377Z"
              fill="#8A8E9E"
            />
          </svg>

          <p>Лицевой счет - вода, отопление</p>
        </div>
        <input
          type="text"
          placeholder="Вода"
          name="FaceNumberWater"
          value={user ? user.FaceNumberWater : ""}
          onChange={handleChange}
        />
      </div>
      <div
        className="button"
        onClick={() => {
          if (!user.Phone.includes("_")) {
            updateUser(user).then((data) => {
              if ((data = "fff")) {
                Notice(
                  "Обновление данных",
                  "Ваши данные успешно обновлены",
                  "success"
                );
              }
            });
          } else {
            Notice("Ошибка", "Номер телефона не заполнен", "danger");
          }
        }}
      >
        сохранить изменения
      </div>
    </div>
  );
}

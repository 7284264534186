import { useEffect, useState } from "react";
import AdminHeader from "../../../Components/AdminHeader";
import AdminMenu from "../../../Components/AdminMenu";
import "./style.css";
import {
  addCategory,
  getCategoriesAdmin,
  updateCategory,
} from "../../../Api/Api";
import Notice from "../../../Func/Func";
export default function AdminCategories() {
  const [cat, setCat] = useState();
  const [change, setChange] = useState(false);
  const [newItem, setNewItem] = useState("");
  const [changeItem, setChangeItem] = useState("");
  const [currentItem, setCurrentItem] = useState(null);
  useEffect(() => {
    getCategoriesAdmin().then((data) => {
      setCat(data);
    });
  }, []);
  useEffect(() => {
    currentItem && setChange(true);
    !currentItem && setChange(false);
    currentItem && setChangeItem(currentItem);
  }, [currentItem]);

  return (
    <div className="cont">
      <AdminMenu></AdminMenu>
      <div className="mainCont">
        <AdminHeader></AdminHeader>
        <div className="adminCatBody">
          <div className="leftAdminCt">
            <h3>Категории:</h3>
            <div className="catContainer">
              {cat &&
                cat.length > 0 &&
                cat.map((em, index) => {
                  return (
                    <div
                      style={
                        currentItem && em.ID == currentItem.ID
                          ? { color: "#f61a41" }
                          : {}
                      }
                      onClick={() => setCurrentItem(em)}
                      key={`cat${index}`}
                    >
                      {em.Name}
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="actionsRight">
            <h3>
              <span
                style={!change ? { color: "#F61A41" } : {}}
                onClick={() => {
                  setCurrentItem(null);
                }}
              >
                Добавление /
              </span>
              <span style={change ? { color: "#F61A41" } : {}}> Изменение</span>
            </h3>
            <p>Заполните поля и сохраните изменения</p>
            {change ? (
              <div className="actionsForm">
                <input
                  type="text"
                  value={changeItem ? changeItem.Name : ""}
                  onChange={(e) =>
                    setChangeItem({ ...changeItem, Name: e.target.value })
                  }
                  placeholder="Заголовок"
                />

                <div className="buttonsSett">
                  <div
                    className="saveButton"
                    onClick={() => {
                      updateCategory(
                        changeItem.Name,
                        currentItem.Name,
                        currentItem.ID
                      ).then((data) => {
                        if (data == 200) {
                          Notice(
                            "Раздел категорий",
                            "Категория успешно изменена",
                            "success"
                          );
                          getCategoriesAdmin().then((data) => {
                            setCat(data);
                          });
                        }
                      });
                    }}
                  >
                    Сохранить
                  </div>
                  <div className="closeButton">Отменить</div>
                </div>
              </div>
            ) : (
              <div className="actionsForm">
                <input
                  type="text"
                  value={newItem}
                  onChange={(e) => setNewItem(e.target.value)}
                  placeholder="Название рубрики"
                />

                <div className="buttonsSett">
                  <div
                    className="saveButton"
                    onClick={() =>
                      newItem.length != 0 &&
                      addCategory(newItem).then((data) => {
                        if (data == 200) {
                          Notice(
                            "Раздел категорий",
                            "Категория успешно добавлена",
                            "success"
                          );
                          setNewItem("");
                          getCategoriesAdmin().then((data) => {
                            setCat(data);
                          });
                        }
                      })
                    }
                  >
                    Добавить
                  </div>
                  <div className="closeButton">Отменить</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

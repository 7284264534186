import React from "react";

const usersTableCSV = (data, fileName) => {
  // Convert the data array into a CSV string
  const csvString = [
    [
      "ФИО покупателя",
      "Номер телефона покупателя",
      "Л/С УК",
      "Л/С ЭЛ. ЭН",
      "Л/С ГАЗ",
      "Л/С ТБО",
      "Сумма покупки Руб",
      "Кэшбэк покупателю Руб",
      "АВ Руб",
      "Название контрагента",
    ], // Specify your headers here

    ...data.map((item) => [
      item.fio,
      item.phone,
      item.lcUk,
      item.lcEl,
      item.lcGas,
      item.lcWater,
      item.summ,
      item.summCash,
      item.summAv,
      item.ukName,
    ]), // Map your data fields accordingly
  ]
    .map((row, index) => row.join(","))
    .join("\n");

  // Create a Blob from the CSV string
  const blob = new Blob([csvString], { type: "text/csv" });

  // Generate a download link and initiate the download
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName || "download.csv";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export default usersTableCSV;

import { useEffect, useState } from "react";
import AdminHeader from "../../../Components/AdminHeader";
import AdminMenu from "../../../Components/AdminMenu";
import { getdillers, updateAdmin } from "../../../Api/Api";
import Notice from "../../../Func/Func";

export default function DillerPage() {
  const [diller, setDiller] = useState();
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("key");
  useEffect(() => {
    getdillers().then((data) => {
      data.map((em) => {
        if (em.ID == myParam) {
          setDiller(em);
        }
      });
    });
  }, []);
  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setDiller({ ...diller, [name]: value });
  };
  return (
    <div className="cont">
      <AdminMenu />
      <div className="mainCont">
        <AdminHeader></AdminHeader>
        <div className="dillerPage">
          <h4>Страница диллера {diller && diller.Login}</h4>
          <h3>Логин</h3>
          <input
            type="text"
            onChange={handleOnChange}
            name="Login"
            value={diller && diller.Login}
          />
          <h3>Пароль</h3>
          <input
            type="text"
            name="Password"
            value={diller && diller.Password}
            onChange={handleOnChange}
          />

          <h3>ИНН</h3>
          <input
            type="text"
            name="Inn"
            onChange={handleOnChange}
            value={diller && diller.Inn}
          />
          <h3>Юр. наименование</h3>
          <input
            type="text"
            name="urName"
            onChange={handleOnChange}
            value={diller && diller.urName}
          />
          <h3>Город</h3>
          <input
            type="text"
            name="City"
            onChange={handleOnChange}
            value={diller && diller.City}
          />
          <h3>Комментарий</h3>
          <textarea
            name="Comment"
            onChange={handleOnChange}
            value={diller && diller.Comment}
          ></textarea>
          <button
            className="saveButton"
            onClick={() => {
              updateAdmin(diller).then((status) => {
                if (status == 200) {
                  getdillers().then((data) => {
                    data.map((em) => {
                      if (em.ID == myParam) {
                        setDiller(em);
                      }
                    });
                  });
                  Notice("Диллер", "Данные диллера обновлены", "success");
                }
              });
            }}
          >
            Сохранить
          </button>
        </div>
      </div>
    </div>
  );
}

import React from "react";

const downloadCSV = (data, fileName, main) => {
  // Convert the data array into a CSV string
  const csvString = [
    [
      "ФИО покупателя",
      "Номер телефона покупателя",
      "Hаименование Ук",
      "Адрес Партнера",
      "Дата покупки",
      "№ ФН",
      "Сумма покупки Руб",
      "Кэшбэк покупателю Руб",
      "АВ Руб",
      "Лимит суммы",
      "Пробел",
      "ИНН Партнера",
      "Период",
      "Сумма покупок",
      "Кэшбэк от партнера %",
      "Размер кэшбэка",
      "АВ от Партнера %",
      "Размер АВ",
      "Сумма к выплате",
    ], // Specify your headers here

    ...data.map((item) => [
      item.fio,
      item.phone,
      item.uk,
      item.adress,
      item.date,
      item.fn,
      item.summ,
      item.cashBack,
      item.av,
      item.limit,
    ]), // Map your data fields accordingly
  ]
    .map((row, index) =>
      index == 1 ? row.concat(main).join(",") : row.join(",")
    )
    .join("\n");

  // Create a Blob from the CSV string
  const blob = new Blob([csvString], { type: "text/csv" });

  // Generate a download link and initiate the download
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName || "download.csv";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export default downloadCSV;

import { useEffect, useState } from "react";
import "./style.css";
import { getQuestions } from "../../Api/Api";
export default function Questions() {
  const [open, setOpen] = useState(0);
  const [questions, setQuestions] = useState();
  useEffect(() => {
    getQuestions().then((data) => {
      setQuestions(data);
    });
  }, []);
  return (
    <div className="questions">
      <div className="nav" onClick={() => window.history.back()}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 7L9 13L15 19"
            stroke="#474753"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p>Частые вопросы</p>
      </div>
      <div className="quest">
        {questions &&
          questions.length > 0 &&
          questions.map((em, index) => {
            return (
              <div className="questItem" onClick={() => setOpen(index)}>
                <div className="vidQuest">
                  <p>{em.Title}</p>
                  <svg
                    style={
                      open == index
                        ? {
                            transform: "rotate(180deg)",
                          }
                        : {}
                    }
                    width="37"
                    height="38"
                    viewBox="0 0 37 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 19C0 8.78273 8.28273 0.5 18.5 0.5C28.7173 0.5 37 8.78273 37 19C37 29.2173 28.7173 37.5 18.5 37.5C8.28273 37.5 0 29.2173 0 19Z"
                      fill="#F61A41"
                    />
                    <path
                      d="M12 17L18.5 23L25 17"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div
                  className="hiddenAnswer"
                  style={
                    open == index
                      ? {
                          padding: "5vw",
                          paddingTop: "7vw",
                          maxHeight: "1000px",
                        }
                      : { padding: "0 5vw" }
                  }
                >
                  {em.Description}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

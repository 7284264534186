import { useNavigate } from "react-router-dom";
import AdminHeader from "../../../Components/AdminHeader";
import AdminMenu from "../../../Components/AdminMenu";
import "./style.css";
import { routes } from "../../../routes";
import { useEffect, useState } from "react";
import { activateDillers, getdillers } from "../../../Api/Api";
import icon from "./icon.svg";
import Notice from "../../../Func/Func";
export default function AdminDillers() {
  const Navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  useEffect(() => {
    getdillers().then((data) => {
      setUsers(data);
    });
  }, []);
  return (
    <div className="cont">
      <AdminMenu />
      <div className="mainCont">
        <AdminHeader></AdminHeader>
        <div className="usersCont">
          <div className="buttonsAndOther">
            <h4>Диллеры</h4>
            <input type="text" value={search} placeholder="Поиск.." />
          </div>
          <div className="partnersTable">
            <div className="adminTableTitle rzd">
              <div className="ava">ID</div>
              <div className="pName">Логин</div>
              <div className="pRub">ИНН</div>
              <div className="pUser">Email</div>
              <div className="pTel">Юр.имя</div>
              <div className="pWeb">Город</div>
              <div className="pAdress">Активация</div>
              <div className="pGr">Пароль</div>
            </div>
            {users &&
              users.length > 0 &&
              users.map((em, index) => {
                return (
                  <div
                    className="admPcol rzd"
                    key={"partner" + index}
                    onClick={() =>
                      Navigate(routes.dillersPage + "?key=" + em.ID)
                    }
                  >
                    <div className="ava">{em.ID}</div>
                    <div className="pName">{em.Login}</div>
                    <div className="pRub">{em.Inn}</div>
                    <div className="pUser">{em.Email}</div>
                    <div className="pTel">{em.urName}</div>
                    <div className="pWeb">{em.City}</div>
                    <div className="active">
                      {em.Activate == 1 ? (
                        <img
                          src={icon}
                          alt=""
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            activateDillers(em.ID, 0).then((status) => {
                              if (status == 200) {
                                getdillers().then((data) => {
                                  setUsers(data);
                                });
                                Notice(
                                  "Диллеры",
                                  "Аккаунт деактивирован",
                                  "success"
                                );
                              }
                            });
                          }}
                        />
                      ) : (
                        <p
                          className="notAct"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            activateDillers(em.ID, 1).then((status) => {
                              if (status == 200) {
                                getdillers().then((data) => {
                                  setUsers(data);
                                  Notice(
                                    "Диллеры",
                                    "Аккаунт активирован",
                                    "success"
                                  );
                                });
                              }
                            });
                          }}
                        ></p>
                      )}{" "}
                    </div>
                    <div className="pGr">{em.Password}</div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

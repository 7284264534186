import { useState } from "react";
import AdminHeader from "../../../Components/AdminHeader";
import AdminMenu from "../../../Components/AdminMenu";
import MainGr from "../../../Components/MainGr";
import "./style.css";
import test from "./test.svg";
import Select from "react-select";
export default function AdminMainPage() {
  const arr = [
    {
      value: "Name",
      label: "2024 год",
    },
    {
      value: "City",
      label: "2023 год",
    },
    {
      value: "category",
      label: "2022 год",
    },
  ];
  const [currentSearch, setCurrentSearch] = useState(arr[0]);
  return (
    <div className="cont">
      <AdminMenu />
      <div className="mainCont">
        <AdminHeader></AdminHeader>
        <div className="mainctBody">
          <div className="mainCtLeft">
            <p className="razrab">Главная</p>
            <div className="InfoCols">
              <div className="infocol">
                <p>
                  Размер <br />
                  АВ
                </p>
                <h4>
                  2.356{" "}
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      width="18"
                      height="18"
                      rx="9"
                      fill="white"
                      fill-opacity="0.3"
                    />
                    <path
                      d="M9.3182 5.08181C9.14246 4.90607 8.85754 4.90607 8.6818 5.08181L5.81802 7.94559C5.64228 8.12133 5.64228 8.40625 5.81802 8.58199C5.99376 8.75772 6.27868 8.75772 6.45442 8.58199L9 6.0364L11.5456 8.58199C11.7213 8.75772 12.0062 8.75772 12.182 8.58199C12.3577 8.40625 12.3577 8.12133 12.182 7.94559L9.3182 5.08181ZM9.45 12.6L9.45 5.40001L8.55 5.40001L8.55 12.6L9.45 12.6Z"
                      fill="white"
                    />
                  </svg>
                  <span>+30%</span>
                </h4>
                <div>
                  Данные <br /> за прошедшую неделю
                </div>
              </div>
              <div className="infocol" style={{ background: "#F61A41" }}>
                <p>
                  Кол-во новых пользователей, кол-во активных, кол-во неактивных
                </p>
                <div className="statFlex">
                  <h4>
                    2.356{" "}
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="18"
                        height="18"
                        rx="9"
                        fill="white"
                        fill-opacity="0.3"
                      />
                      <path
                        d="M9.3182 5.08181C9.14246 4.90607 8.85754 4.90607 8.6818 5.08181L5.81802 7.94559C5.64228 8.12133 5.64228 8.40625 5.81802 8.58199C5.99376 8.75772 6.27868 8.75772 6.45442 8.58199L9 6.0364L11.5456 8.58199C11.7213 8.75772 12.0062 8.75772 12.182 8.58199C12.3577 8.40625 12.3577 8.12133 12.182 7.94559L9.3182 5.08181ZM9.45 12.6L9.45 5.40001L8.55 5.40001L8.55 12.6L9.45 12.6Z"
                        fill="white"
                      />
                    </svg>
                    <span>+30%</span>
                  </h4>
                  <h4>
                    2.356{" "}
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="18"
                        height="18"
                        rx="9"
                        fill="white"
                        fill-opacity="0.3"
                      />
                      <path
                        d="M9.3182 5.08181C9.14246 4.90607 8.85754 4.90607 8.6818 5.08181L5.81802 7.94559C5.64228 8.12133 5.64228 8.40625 5.81802 8.58199C5.99376 8.75772 6.27868 8.75772 6.45442 8.58199L9 6.0364L11.5456 8.58199C11.7213 8.75772 12.0062 8.75772 12.182 8.58199C12.3577 8.40625 12.3577 8.12133 12.182 7.94559L9.3182 5.08181ZM9.45 12.6L9.45 5.40001L8.55 5.40001L8.55 12.6L9.45 12.6Z"
                        fill="white"
                      />
                    </svg>
                    <span>+30%</span>
                  </h4>
                </div>
                <div>
                  Новые <br />
                  пользователи
                </div>
              </div>
              <div className="infocol">
                <p>
                  Размер <br />
                  кешбека
                </p>
                <h4>
                  2.356{" "}
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      width="18"
                      height="18"
                      rx="9"
                      fill="white"
                      fill-opacity="0.3"
                    />
                    <path
                      d="M9.3182 5.08181C9.14246 4.90607 8.85754 4.90607 8.6818 5.08181L5.81802 7.94559C5.64228 8.12133 5.64228 8.40625 5.81802 8.58199C5.99376 8.75772 6.27868 8.75772 6.45442 8.58199L9 6.0364L11.5456 8.58199C11.7213 8.75772 12.0062 8.75772 12.182 8.58199C12.3577 8.40625 12.3577 8.12133 12.182 7.94559L9.3182 5.08181ZM9.45 12.6L9.45 5.40001L8.55 5.40001L8.55 12.6L9.45 12.6Z"
                      fill="white"
                    />
                  </svg>
                  <span>+30%</span>
                </h4>
                <div>
                  Данные <br /> за прошедшую неделю
                </div>
              </div>
            </div>
            <h4 className="ofd">Аналитика покупок пользователей</h4>
            <div className="period">
              <p>Выбрать период</p>
              <Select
                value={currentSearch}
                onChange={setCurrentSearch}
                name="Name"
                options={arr}
              />
            </div>
            <div className="gr">
              <MainGr></MainGr>
            </div>
          </div>
          <div className="mainCtRight">
            <p className="razrab">Партнеры</p>
            <img src={test} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

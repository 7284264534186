import "./style.css";
import act from "../Sales/img/act.png";
import logo from "../Sales/img/logo.png";
import { getSale } from "../../Api/Api";
import { useEffect, useState } from "react";
import formDate from "../../Func/Time";
export default function SalePage() {
  const url = new URL(window.location);
  const id = url.searchParams.get("key");
  const [sale, setSale] = useState();
  useEffect(() => {
    getSale(id).then((data) => {
      setSale(data[0]);
    });
  }, []);
  return (
    <div className="salePage">
      <div className="nav" onClick={() => window.history.back()}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 7L9 13L15 19"
            stroke="#474753"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p>Все акции</p>
      </div>
      <div className="actionItem">
        <img src={sale && sale.Image} alt="" />
        <div className="actionDesc">
          <img src={sale && sale.Logo} alt="" />
          <p>{sale && sale.Name}</p>
        </div>
      </div>
      <h3>{sale && sale.Title}</h3>
      <p className="saleText">{sale && sale.Description}</p>
      <div className="dateSale">
        с {sale && formDate(sale.TimeBegin)} по{" "}
        {sale && formDate(sale.TimeOfEnd)}
      </div>
    </div>
  );
}

export const routes = {
  main: "/main",
  auth: "/auth",
  registration: "/registration",
  news: "/news",
  sales: "/sales",
  partners: "/partners",
  buy: "/buy",
  firstScreen: "/hello",
  instructions: "/instructions",
  completeRegister: "/completeRegister",
  pageofnews: "/pageofnews",
  salePage: "/salePage",
  partnerPage: "/partnerPage",
  questions: "/questions",
  profile: "/profile",
  //Страницы админа
  authAdmin: "/authAdmin",
  adminPartners: "/AdminPartners",
  adminMain: "/AdminMain",
  adminActions: "/AdminActions",
  adminNews: "/AdminNews",
  adminQuestions: "/AdminQuestions",
  adminUsers: "/AdminUsers",
  adminDillets: "/AdminDillers",
  adminPartnerData: "/AdminPartnerData",
  adminAddPartner: "/AdminAddPartner",
  adminUserPage: "/AdminUserPage",
  adminUserSellData: "/AdminUserSellData",
  dillersPage: "/AdminDillerPage",
  rubrik: "/AdminCategories",
};

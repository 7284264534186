import React, { PureComponent } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Декабрь",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Январь",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Февраль",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Март",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Апрель",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Май",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Июнь",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export default function MainGr() {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="uv"
          stackId="1"
          stroke="#F61A41"
          fill="#F61A41"
        />
        <Area
          type="monotone"
          dataKey="pv"
          stackId="1"
          stroke="#8A8E9E"
          fill="#8A8E9E"
        />
        <Area
          type="monotone"
          dataKey="amt"
          stackId="1"
          stroke="#474753"
          fill="#474753"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

import "./style.css";
import i1 from "./fff.png";
import { useEffect, useState } from "react";
import { getNew } from "../../Api/Api";
export default function PageOfNews() {
  const url = new URL(window.location);
  const id = url.searchParams.get("key");
  const [newData, setNewData] = useState();
  useEffect(() => {
    getNew(id).then((data) => {
      setNewData(data[0]);
    });
  }, []);
  return (
    <div className="pageOfNews">
      <div className="nav" onClick={() => window.history.back()}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 7L9 13L15 19"
            stroke="#474753"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p>Все новости</p>
      </div>
      <h2>{newData && newData.Title}</h2>
      <img src={newData && newData.Image} alt="" />
      <span>{newData && newData.Description}</span>
      <article>{newData && newData.DateTime}</article>
    </div>
  );
}

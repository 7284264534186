export default function formDate(value) {
  const date = new Date(value);
  let day = date.getDate().length == 1 ? "0" + date.getDate() : date.getDate();
  let month = date.getMonth();
  month += 1;
  if (month.toString().length == 1) {
    month = "0" + month;
  }
  if (day.toString().length == 1) {
    day = "0" + day;
  }
  return day + "." + month + "." + date.getFullYear();
}

import { useLocation } from "react-router-dom";
import "./style.css";
import { routes } from "../../routes";
import { useContext } from "react";
import { AdminContext } from "../../Context/admin";
import { Fade } from "react-reveal";
export default function AdminHeader() {
  const location = useLocation();
  const [admin, setAdmin] = useContext(AdminContext);
  return (
    <div className="adminHeader">
      <div className="backBtn" onClick={() => window.history.back()}>
        <svg
          width="14"
          height="10"
          viewBox="0 0 14 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.04038 4.54038C0.78654 4.79422 0.78654 5.20578 1.04038 5.45962L5.17695 9.59619C5.4308 9.85003 5.84235 9.85003 6.09619 9.59619C6.35003 9.34235 6.35003 8.9308 6.09619 8.67695L2.41924 5L6.09619 1.32304C6.35004 1.0692 6.35004 0.657646 6.09619 0.403806C5.84235 0.149965 5.4308 0.149965 5.17696 0.403805L1.04038 4.54038ZM13.5 4.35L1.5 4.35L1.5 5.65L13.5 5.65L13.5 4.35Z"
            fill="white"
          />
        </svg>
        Назад
      </div>
      <p className="navText">
        Админ панель /{" "}
        <span>
          {location.pathname == routes.adminMain && "Главная"}
          {location.pathname == routes.adminPartners && "Партнеры"}
          {location.pathname == routes.adminActions && "Акции"}
          {location.pathname == routes.adminDillets && "Диллеры"}
          {location.pathname == routes.adminNews && "Новости"}
          {location.pathname == routes.adminQuestions && "Частые вопросы"}
          {location.pathname == routes.adminUsers && "Пользователи"}
          {location.pathname == routes.adminUserSellData &&
            "Профиль пользователя"}
          {location.pathname == routes.adminPartnerData && "Страница партнера"}
          {location.pathname == routes.dillersPage && "Страница диллера"}
          {location.pathname == routes.rubrik && "Рубрики"}
        </span>
      </p>
      <div className="profileSt">
        <div>
          <p>{admin && admin.Login}</p>
          <span>
            {admin && admin.isAdmin == "1" ? "super admin" : "Диллер"}
          </span>
        </div>
        <div className="av"></div>
      </div>
    </div>
  );
}
